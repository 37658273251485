import logo from '../../../assets/images/potrfolio/sputnik/logo.webp';
import logo2 from '../../../assets/images/potrfolio/sputnik/coin.webp';
import last from '../../../assets/images/potrfolio/sputnik/last.svg';
import screen1 from '../../../assets/images/potrfolio/sputnik/screen1.webp';
import screen2 from '../../../assets/images/potrfolio/sputnik/screen2.webp';
import screen3 from '../../../assets/images/potrfolio/sputnik/screen3.webp';
import screen4 from '../../../assets/images/potrfolio/sputnik/screen4.webp';
import circle1 from '../../../assets/images/potrfolio/sputnik/circle1.svg';
import circle2 from '../../../assets/images/potrfolio/sputnik/circle2.svg';
import circle3 from '../../../assets/images/potrfolio/sputnik/circle3.svg';
import './SputnikPage.css';

const SputnikPage = () => {

    return (
        <>
             <img className='sputnik__logo screen' alt='' src={logo}></img>
            <img className='sputnik__logo-coin screen' alt='' src={logo2}></img>
            <img className='screen sputnik__screen' alt='' src={screen1}></img>
            <img className='screen screen_no-border screen_no-padding' alt='' src={screen2}></img>
            <img className='screen screen_no-border' alt='' src={screen3}></img>
            <img className='screen' alt='' src={screen4}></img>
            <img className='sputnik__logo-planeta screen' alt='' src={last}></img>

            <img className='sputnik__circle1' alt='' src={circle1}></img>
            <img className='sputnik__circle2' alt='' src={circle2}></img>
            <img className='sputnik__circle3' alt='' src={circle3}></img>
        </>
    )
}
export default SputnikPage