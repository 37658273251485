
import Banner from './Banner/Banner';
import Clients from './Clients/Clients';
import ContactUs from './ContactUs/ContactUs';
import './Main.css';
import Mascot from './Mascot/Mascot';
import Numbers from './Numbers/Numbers';
import Process from './Process/Process';
import Projects from './Projects/Projects';
import ProjectsHeading from './ProjectsHeading/ProjectsHeading';
import Stats from './Stats/Stats';

function Main() {
    return (
        <div className="main">
            <Banner />
            <Mascot />
            <Numbers />
            <ProjectsHeading />
            <Projects />
            <Process />
            <ContactUs />
            <Clients />
            <Stats />
        </div>
    );
}

export default Main;
