import HiggsField from './HiggsField/HiggsField';
import JellyBet from './JellyBet/JellyBet';
import Mgc from './Mgc/Mgc';
import Pet4Me from './Pet4Me/Pet4Me';
import './Projects.css';
import Sputnik from './Sputnik/Sputnik';
import Zam from './Zam/Zam';
import OpenReview from './OpenReview/OpenReview';
 
function Projects() {
  return (
    <div className='projects'>
       <Zam company={'cases/zam'}/>
       <Sputnik company={'cases/sputnik'}/>
       <OpenReview company={'cases/openreview'}/>
       <JellyBet company={'cases/jellybet'}/>
       <HiggsField company={'cases/higgs'}/>
       <Pet4Me company={'cases/pet4me'}/>
       <Mgc company={'cases/mgc'}/>
    </div>
  );
}
 
export default Projects