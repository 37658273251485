import CompTemplate from '../CompTemplate/CompTemplate';
import './Pet4Me.css';
import card from '../../../../assets/images/potrfolio/pet4me/banerBig.webp'

function Pet4Me({ company }) {
    return (
        <CompTemplate
            company={company}
            className={'pet4me-project-card'}
            project_name={'PET4ME'}
            project_description={'Marketplace for selling dogs of&nbsp;all breeds'}
        >
            <div className='pet4me-project-card__content'>
                <img style={{ maxWidth: '1600px' }} src={card} alt=''></img>
            </div>
        </CompTemplate>
    );
}

export default Pet4Me