
import { MascotCircle1Icon, MascotCircle2Icon, MascotCircle3Icon } from '../../../assets/icons/icons';
import './Mascot.css';

import robot from '../../../assets/images/main/mascot/robot.png'
import robot_eyes from '../../../assets/images/main/mascot/eye.png'



function Mascot() {
    return (
        <div className="mascot">
            <div className="mascot__bg">
                <MascotCircle1Icon mainClassName={'mascot__bg-circle-1'} />
                <MascotCircle2Icon mainClassName={'mascot__bg-circle-2'} />
                <MascotCircle3Icon mainClassName={'mascot__bg-circle-3'} />
                {/* <MascotSquareIcon mainClassName={'mascot__bg-squares'} /> */}
                {Array.from({length: 7}).map((item, i)=>(
                    <div className={`mascot__bg-square mascot__bg-square_type_${i + 1}`}></div>
                ))}
                
                {/* <div className='mascot__bg-square mascot__bg-square_type_2'></div>
                <div className='mascot__bg-square mascot__bg-square_type_3'></div> */}

            </div>
            <img className='mascot__robot' src={robot} alt='mascot'></img>
            <img className='mascot__robot-eyes' src={robot_eyes} alt='mascot - eyes'></img>

            <p className='mascot__hello'>HELLO WORLD...</p>
        </div>
    );
}

export default Mascot;
