import card from '../../../../assets/images/potrfolio/guestme/card.png'
import './Guestme.css';
import PhoneTemplate from '../PhoneTemplate/PhoneTemplate';
import useWindowSize from '../../../../assets/hooks/useWindowSize';

function Guestme({ company }) {
    const windowSize = useWindowSize()
    return (
        <PhoneTemplate
            company={company}
            className={'guestme-project-card'}
            project_name={'Guestme'}
            project_description={windowSize.width > 760 ? 'Application for announcing your parties' : ''}
        >
            <div className='guestme-project-card__content'>
                <img className='guestme-project-card__content__img' src={card} alt=''></img>
            </div>
        </PhoneTemplate>
    );
}

export default Guestme