import { LinkedInIcon, MailIcon, TelegramIcon, WhatsAppIcon } from "../icons/icons";
import { NumbersAppIcon, NumbersBrandingIcon, NumbersCryptoIcon, NumbersWebsitesIcon } from "../icons/projectNumbers";
import { sendMetriс } from "./utils";
import muchi from '../../assets/images/equity/muchi.webp'
import muchi_logo from '../../assets/images/equity/muchi-logo.png'
import token from '../../assets/images/equity/token.webp'
import token_logo from '../../assets/images/equity/token-logo.png'
import paraliq from '../../assets/images/equity/paraliq.webp'
import paraliq_logo from '../../assets/images/equity/paraliq-logo.png'
import zam from '../../assets/images/equity/zam.webp'
import zam_logo from '../../assets/images/equity/zam-logo.png'
import surikov from '../../assets/images/team/MSurikov.webp'
import edward from '../../assets/images/team/edvard.webp'
import surikovA from '../../assets/images/team/ASurikov.webp'
import tishkin from '../../assets/images/team/tishkin.webp'
import sadekov from '../../assets/images/team/sadekov.webp'
import lazuta from '../../assets/images/team/lazuta.webp'
import mail from '../../assets/images/contacts/email.webp'
import tg from '../../assets/images/contacts/tg.webp'
import wt from '../../assets/images/contacts/wt.webp'
import linkin from '../../assets/images/contacts/in.webp'
import mail_mobile from '../../assets/images/contacts/mailMobile.webp'
import tg_mobile from '../../assets/images/contacts/tgMobile.webp'
import wt_mobile from '../../assets/images/contacts/wtMobile.webp'
import linkin_mobile from '../../assets/images/contacts/inMobile.webp'
import mgc from '../../assets/images/equity/mgc.webp'
import mgc_logo from '../../assets/images/equity/mgc-logo.png'

export const MAIN_URL = "https://app.cabahub.com"

export const CONTACT_LINKS = [
    {
        name: 'Email',
        path: 'mailto:hello@cabahub.com?subject=Request form cabahub.com',
        Icon: MailIcon,
        isContrast: true,
        goal_id: 'CLICK_MAIL',
        link_name: 'hello@cabahub.com',
        logo: mail,
        logo_mobile: mail_mobile
    },
    {
        name: 'Telegram',
        path: 'https://t.me/CabaTech',
        Icon: TelegramIcon,
        isContrast: false,
        goal_id: 'CLICK_TELEGRAM',
        link_name: '@CabaTech',
        logo: tg,
        logo_mobile: tg_mobile
    },
    {
        name: 'WhatsApp',
        path: 'https://wa.me/12062650359',
        Icon: WhatsAppIcon,
        isContrast: false,
        goal_id: 'CLICK_WHATSAPP',
        link_name:'+1 206 265-03-59',
        logo: wt,
        logo_mobile: wt_mobile
    },
    {
        name: 'Linked in',
        path: 'https://www.linkedin.com/company/caba-tech/',
        Icon: LinkedInIcon,
        isContrast: false,
        goal_id: 'CLICK_LINKEDIN',
        link_name:'CabaHub',
        logo: linkin,
        logo_mobile: linkin_mobile
    },
]

export const PROJECT_NUMBERS = [
    {
        title: 'WEB SITES',
        count: '388',
        icon: NumbersWebsitesIcon,
    },
    {
        title: 'APPLICATIONS',
        count: '45',
        icon: NumbersAppIcon,
    },
    {
        title: 'BRANDING',
        count: '69',
        icon: NumbersBrandingIcon,
    },
    {
        title: 'CRYPTO',
        count: '31',
        icon: NumbersCryptoIcon,
    },
]

export const STATS = [
    {
        title: 'Cups of Coffee',
        count: '40.671',
    },
    {
        title: 'Happy Clients',
        count: '382',
    },
    {
        title: 'Projects Finished',
        count: '563',
    },
    {
        title: 'Countries',
        count: '33',
    },
]

export const CATEGORIES = [
    {
        name: 'Websites',
    },
    {
        name: 'Applications',
    },
    {
        name: 'Branding',
    },
    {
        name: 'Crypto',
    },
    {
        name: 'Other',
    },
]
export const MENU = [
    {
        link_name: '',
        title: 'Home'
    },
    {
        link_name: 'services',
        title: 'SERVICES',
    },
    {
        link_name: 'cases',
        title: 'CASES'
    },
    {
        link_name: 'white-label-solutions',
        title: 'WHITE-LABEL SOLUTIONS',
    },
    // {
    //     link_name: 'company',
    //     title: 'Company',
    // },
    // {
    //     link_name: 'technology',
    //     title: 'TECHNOLOGY',
    // },
    // {
    //     link_name: 'partners',
    //     title: 'PARTNERS'
    // },
    {
        link_name: 'contacts',
        title: 'Contacts'
    },
]
export const EQUITY_CARD = [
    {
        img: muchi,
        name: 'MUCHI',
        logo: muchi_logo,
        text: 'Our latest endeavour — the platform for readers and content creators to share their passion and profits.'
    },
    {
        img: token,
        name: 'TOKEN STATES',
        logo: token_logo,
        text: 'Crypto is not only for online and digital. It can buy houses, too'
    },
    {
        img: paraliq,
        name: 'PARALIQ',
        logo: paraliq_logo,
        text: 'An all-around platform that makes crypto casinos’ life easier, and more profitable'
    },
    {
        img: zam,
        name: 'ZAM APP',
        logo: zam_logo,
        text: 'An app for doctors and patients to track the medications intake and results'
    },
    // {
    //     img: mgc,
    //     name: 'MGC Pharma',
    //     logo: mgc_logo,
    //     text: 'Full technical support of a new generation pharmaceutical company'
    // },
]
export const TEAM =[
    {
        name:'Mikhail Surikov',
        member:'CTO and Head of Front End Development',
        photo: surikov,
    },
    {
        name:'Edward Zwetzig',
        member:'Design Lead and Product Manager',
        photo: edward,
    },
    {
        name:'Alexey Surikov',
        member:'Head of Back End Development',
        photo: surikovA,
    },
    {
        name:'Alexey Tishkin',
        member:'Senior Designer',
        photo: tishkin,
    },
    {
        name:'Marat Sadekov',
        member:'Head of Blockchain Development',
        photo: sadekov,
    },
    {
        name:'Nadegda Lazuta',
        member:'Front End Developer',
        photo: lazuta,
    },
]