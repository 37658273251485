import logo from '../../../assets/images/potrfolio/guestme/logo.webp';
import screen1 from '../../../assets/images/potrfolio/guestme/1.webp';
import screen2 from '../../../assets/images/potrfolio/guestme/2.webp';
import grid1 from '../../../assets/images/potrfolio/guestme/3.webp';
import grid2 from '../../../assets/images/potrfolio/guestme/4.webp';
import grid3 from '../../../assets/images/potrfolio/guestme/5.webp';
import grid4 from '../../../assets/images/potrfolio/guestme/6.webp';
import calendar from '../../../assets/images/potrfolio/guestme/calendar.webp';
import baloon from '../../../assets/images/potrfolio/guestme/baloon.webp'
import last from '../../../assets/images/potrfolio/guestme/last.webp'
import circle from '../../../assets/images/potrfolio/guestme/circle.png'
import './GuestmePage.css';

const GuestmePage = () => {

  return (
    <>
      <img className=' guestme__screen guestme__logo' alt='' src={logo}></img>
      <img className='guestme__screen guestme__screen_star-img ' alt='' src={screen1}></img>
      <img className='guestme__screen guestme__screen_circle-img ' alt='' src={screen2}></img>
      <div className='guestme__grid'>
        <img style={{ gridArea: 'screen1' }} className='guestme__grid__screen' alt='' src={grid1}></img>
        <img style={{ gridArea: 'calendar' }} className='guestme__screen_calendar' alt='' src={calendar}></img>
        <img style={{ gridArea: 'screen2' }} className='guestme__grid__screen' alt='' src={grid2}></img>
        <img style={{ gridArea: 'screen3' }} className='guestme__grid__screen' alt='' src={grid3}></img>
        <img style={{ gridArea: 'baloon' }} className='guestme__screen_baloon' alt='' src={baloon}></img>
        <img style={{ gridArea: 'screen4' }} className='guestme__grid__screen' alt='' src={grid4}></img>
      </div>
      <div className='guestme__circle__box'>
      <img className='screen guestme__screen_last' alt='' src={last}></img>
      <img className='screen guestme__circle' alt='' src={circle}></img>
      </div>
    </>

  )
}
export default GuestmePage