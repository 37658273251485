import screen1 from '../../../assets/images/potrfolio/metamedix/LION.webp';
import screen2 from '../../../assets/images/potrfolio/metamedix/CHEETAH.webp';
import screen3 from '../../../assets/images/potrfolio/metamedix/FOX.webp';
import screen4 from '../../../assets/images/potrfolio/metamedix/HIPPO.webp';
import screen5 from '../../../assets/images/potrfolio/metamedix/PANDA.webp';
import screen6 from '../../../assets/images/potrfolio/metamedix/BULL.webp';
import './MetaMedixPage.css';

const MetaMedixPage = () => {

    return (
        <>
            <img className='screen' alt='' src={screen1}></img>
            <img className='screen' alt='' src={screen2}></img>
            <img className='screen' alt='' src={screen3}></img>
            <img className='screen' alt='' src={screen4}></img>
            <img className='screen' alt='' src={screen5}></img>
            <img className='screen' alt='' src={screen6}></img>
        </>
    )
}
export default MetaMedixPage