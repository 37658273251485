import ICON_NULL from '../images/wl/card1.webp';
import ICON_ONE from '../images/wl/card2.webp';
import ICON_TWO from '../images/wl/card3.webp';
import ICON_THREE from '../images/wl/card4.webp';
import ICON_FOUR from '../images/wl/card5.webp';
import ICON_FIVE from '../images/wl/card6.webp';
import card1 from '../images/wl/solutions/card1.png';
import card2 from '../images/wl/solutions/card2.png';
import card3 from '../images/wl/solutions/card3.png';
import card4 from '../images/wl/solutions/card4.png';
import card5 from '../images/wl/solutions/card5.png';
import card6 from '../images/wl/solutions/card6.png';

export const WL_MAIN_TEXT =
  'Caba Hub is&nbsp;renowned for its comprehensive range of&nbsp;in-house and white label solutions. Ideal for businesses aiming to&nbsp;enhance their digital services without the intricacies of&nbsp;developing them independently, these solutions are designed for smooth integration and customization, allowing you to&nbsp;offer state-of-the-art digital services under your own brand.';

export const WL_CARDS_DATA = [
  {
    id: 0,
    translitName: 'password-system',
    heading: 'Password<br />Security System',
    subtitle:
      'Our sophisticated system provides top-tier security for&nbsp;all your&nbsp;passwords, ensuring exceptional protection in&nbsp;the digital realm.',
    cardBackground: 'wl-card_type_bg-null',
    icon: ICON_NULL,
    text: 'Our password manager addresses storage and access issues for various resources at&nbsp;large companies. With local storage, cryptographic encryption, and access control, you can be&nbsp;sure that confidential information will be&nbsp;securely protected and available only to&nbsp;authorized users.',
    img: card1,
  },
  {
    id: 1,
    translitName: 'platform-developments',
    heading: 'Platform<br />Developments',
    subtitle:
      'Specializing in&nbsp;diverse areas such as&nbsp;marketplaces, social media platforms, and e-commerce websites, we&nbsp;create platforms that prioritize user experience and engagement.',
    cardBackground: 'wl-card_type_bg-one',
    icon: ICON_ONE,
    text: `This service is&nbsp;dedicated to&nbsp;revolutionizing digital interactions by&nbsp;designing cutting-edge platforms that seamlessly blend technology and creativity to&nbsp;provide a&nbsp;one-of-a-kind user experience. Whether it&rsquo;s developing interactive entertainment platforms or&nbsp;streamlining communication networks, we&nbsp;create solutions that exceed expectations and help elevate the digital landscape.`,
    img: card2,
  },
  {
    id: 2,
    translitName: 'file-manager',
    heading: 'Caba Suite<br />File Manager',
    subtitle:
      'With an&nbsp;algorithm more advanced than Dropbox, this file manager excels in&nbsp;efficient file organization and management, enhancing user productivity..',
    cardBackground: 'wl-card_type_bg-two',
    icon: ICON_TWO,
    text: 'Our innovative file manager organizes documents, media files, and applications in&nbsp;a&nbsp;user-friendly interface, making it&nbsp;easy to&nbsp;locate and access important content quickly. With advanced search and sorting options, users can efficiently manage their files and streamline their workflow, ultimately improving productivity and reducing frustration.',
    img: card3,
    case_link: `cabasuite`
  },
  {
    id: 3,
    translitName: 'tokenization-platforms',
    heading: 'Tokenization<br />Platforms',
    subtitle:
      'Our platforms offer innovative solutions for asset tokenization, revolutionizing the way digital transactions and asset management are handled.',
    cardBackground: 'wl-card_type_bg-three',
    icon: ICON_THREE,
    text: `Our groundbreaking tokenization platform is&nbsp;revolutionizing digital transactions by&nbsp;securely converting sensitive data into unique tokens, providing a&nbsp;layer of&nbsp;protection against cyber threats. With our cutting-edge technology, businesses can streamline their payment processes while ensuring the utmost security for their customers information.`,
    img: card4,
  },
  {
    id: 4,
    translitName: 'ai',
    heading: 'AI-Powered<br />Stock Exchange',
    subtitle:
      'Integrating AI&nbsp;technology, we&nbsp;provide a&nbsp;novel approach to&nbsp;stock trading, delivering insights and efficiency surpassing conventional methods.',
    cardBackground: 'wl-card_type_bg-four',
    icon: ICON_FOUR,
    text: 'Introducing the unique AI-powered stock exchange, where advanced algorithms and machine learning technology work together to&nbsp;predict market trends and make profitable trades in&nbsp;real-time. Say goodbye to&nbsp;human error and let artificial intelligence take the lead in&nbsp;maximizing your investment opportunities.',
    img: card5,
  },
  {
    id: 5,
    translitName: 'games-development',
    heading: 'Online Casino<br />Games Development',
    subtitle:
      'Utilizing the latest in&nbsp;Web&nbsp;2.0 and Web&nbsp;3.0&nbsp;technologies, we&nbsp;develop immersive online casino games that offer an&nbsp;unrivaled gaming experience.',
    cardBackground: 'wl-card_type_bg-five',
    icon: ICON_FIVE,
    text: 'Our cutting-edge online casino games development is&nbsp;revolutionizing the industry with the latest in&nbsp;Web&nbsp;2.0 and Web&nbsp;3.0&nbsp;technologies. By&nbsp;harnessing the power of&nbsp;these advancements, we&nbsp;are able to&nbsp;deliver a&nbsp;seamless and immersive gaming experience like never before, setting a&nbsp;new standard for online gambling.',
    img: card6,
    case_link: 'paraliq',
  },
];
