import card from '../../../../assets/images/potrfolio/BrainSpace/BrainSpace.webp'
import './BrainSpace.css';
import { Link, useLocation } from 'react-router-dom';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import CompTemplate from '../CompTemplate/CompTemplate';

function BrainSpace({company}) {
    const windowSize = useWindowSize()
    let location = useLocation();
    return (
        <CompTemplate
            company={company}
            className={'barinspace-project-card'}
            project_name={'Brain Space'}
            project_description={'Psychologists online service'}
        >
             <Link to={`/${company}`} state={{ backgroundLocation: location }}>
            <div className='barinspace-project-card__content'>
              <img className='barinspace-project-card__content__img' src={card} alt=''></img>
            </div>
            </Link>
        </CompTemplate>
    );
}

export default BrainSpace;