import './Partners.css';

import bg from '../../assets/images/main/banner/main-banner-bg.webp'
import bg_mobile from '../../assets/images/main/banner/main-banner-bg-mobile.webp'
import circle_bg from '../../assets/images/main/bg.webp'
function Partners() {
    return (
        <div className='partners'>
            <div className='partners__title'>
                <p className='partners__title__text'>partners</p>
                <img className='banner__bg banner__bg_pc' src={bg} alt=""></img>
                <img className='banner__bg banner__bg_mobile' src={bg_mobile} alt=""></img>
            </div>
            <div className='partners__text__box'>
                <p className='partners__text_small'>We treat our partners like they are a part of our team, and that’s why we think that our only objective is to be transparent. That is how you build trust.</p>
                <p className='partners__text_blue'>CURRENT PARTNERS</p>
                <p className='partners__text'>GraftPolymer · Sputnik · Paraliq · GraftBio</p>
                {/* <p className='partners__text'>MGC · Sellavi · GraftPolymer · Sputnik · Paraliq · GraftBio</p> */}
                <img className='partners__bg_circle' alt='' src={circle_bg}></img>
            </div>
        </div>
    );
}

export default Partners