import card from '../../../../assets/images/potrfolio/review/card.png'
import './OpenReview.css';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import PhoneTemplate from '../PhoneTemplate/PhoneTemplate';

function OpenReview({ company }) {
    const windowSize = useWindowSize()
    return (
        <PhoneTemplate
            company={company}
            className={'review-project-card'}
            project_name={'Open Review'}
            project_description={windowSize.width > 760 ? 'Book app for evaluating and searching' : ''}
        >
            <div className='review-project-card__content'>
                <img className='review-project-card__content__img' src={card} alt=''></img>
            </div>
        </PhoneTemplate>
    );
}

export default OpenReview