import './Header.css';
import { NavLink, Link, useLocation } from 'react-router-dom';
import logo from '../../assets/images/main/header/logo.png'
import { MENU } from '../../assets/utils/constants';
function Header() {

    const { state } = useLocation();
    const isInsideModal = state?.backgroundLocation;
    return (
        <>
            {!isInsideModal && (
                <header className="header">
                    <div className='header__box' onClick={(e) => e.stopPropagation()}>
                        <Link className='header__box-logo header__box-logo_type_desktop' to='/'>
                            <img className='header__box__logo' src={logo} alt=''></img>
                        </Link>
                        <div className='header__box-nav-links'>
                            {MENU.map((item) => {
                                return (
                                    <NavLink to={`/${item.link_name}`} className={({ isActive }) =>
                                    (isActive ?
                                        "header__box-nav-links-link_type_active"
                                        : "header__box-nav-links-link_type_not-active")}>
                                        {item.title}
                                    </NavLink>
                                )
                            })}
                        </div>
                    </div>
                </header>
            )}

        </>
    );
};

export default Header;