export function LikeIcon({ mainClassName }) {
    return (
      <svg
        width={24}
        height={22}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={mainClassName}
      >
        <path
          d="M16.933 0C21.16 0 24 4.098 24 7.92 24 15.661 12.213 22 12 22c-.213 0-12-6.339-12-14.08C0 4.098 2.84 0 7.067 0 9.493 0 11.08 1.251 12 2.351 12.92 1.251 14.507 0 16.933 0z"
          fill="#fff"
        />
      </svg>
    );
  }
  export function LikeIconMobile({ mainClassName }) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={mainClassName}
      >
        <path
          d="M10.878 2C13.343 2 15 4.421 15 6.68 15 11.254 8.124 15 8 15c-.124 0-7-3.746-7-8.32C1 4.421 2.657 2 5.122 2 6.538 2 7.463 2.74 8 3.39 8.537 2.74 9.462 2 10.878 2z"
          fill="#fff"
        />
      </svg>
    );
  }
  
  export function ShareIcon({ mainClassName, strokeClassName }) {
    return (
        <svg
          width={28}
          height={28}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={mainClassName}
        >
          <path
            d="M17.857 10.334h2.572c1.42 0 2.571 1.094 2.571 2.444v9.778C23 23.906 21.85 25 20.43 25H7.572C6.152 25 5 23.906 5 22.556v-9.778c0-1.35 1.152-2.444 2.572-2.444h2.571M14 17.667V3M17.857 6.667L14 3l-3.857 3.667"
            stroke="#000536"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className={strokeClassName}
          />
        </svg>
      );
  }
  export function ShareIconMobile({ mainClassName, strokeClassName }) {
    return (
      <svg
        width={16}
        height={16}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={mainClassName}
      >
        <path
          d="M10.204 5.904h1.47c.811 0 1.469.626 1.469 1.397v5.588c0 .77-.658 1.396-1.47 1.396H4.328c-.812 0-1.47-.625-1.47-1.396V7.3c0-.771.658-1.397 1.47-1.397h1.47M8 10.095V1.714M10.204 3.81L8 1.713 5.796 3.809"
          stroke="#000536"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className={strokeClassName}
        />
      </svg>
    );
  }
  