
import PortfolioTemplate from './PortfolioTemplate/PortfolioTemplate';
import './Portfolio.css';
import { useParams } from 'react-router-dom';
import Pet4MePage from './Pet4MePage/Pet4MePage';
import ZamPage from './ZamPage/ZamPage';
import SputnikPage from './SputnikPage/SputnikPage';
import JellyBetPage from './JellyBetPage/JellyBetPage';
import HiggsPage from './HiggsPage/HiggsPage';
import MetaMedixPage from './MetaMedixPage/MetaMedixPage';
import OpenReviewPage from './OpenReviewPage/OpenReviewPage';
import GuestmePage from './GuestmePage/GuestmePage';
import CabaSuitePage from './CabaSuitePage/CabaSuitePage';
import ParaliqPage from './ParaliqPage/ParaliqPage';
import MGCPage from './MGCPage/MGCPage';
import SBAPage from './SBAPage/SBAPage';
import BoomSitePage from './BoomSitePage/BoomSitePage';
import BigBroPage from './BigBroPage/BigBroPage';
import BrainSpacePage from './BrainSpacePage/BrainSpacePage';
import BeYouPage from './BeYouPage/BeYouPage';
import DivanchikPage from './DivanchikPage/DivanchikPage';

function getTemplate(id) {
  switch (id) {
    case 'pet4me': {
      return <Pet4MePage />
    }
    case 'openreview': {
      return <OpenReviewPage />
    }
    case 'zam': {
      return <ZamPage />
    }
    case 'sputnik': {
      return <SputnikPage />
    }
    case 'jellybet': {
      return <JellyBetPage />
    }
    case 'higgs': {
      return <HiggsPage />
    }
    case 'metamedix': {
      return <MetaMedixPage />
    }
    case 'guestme': {
      return <GuestmePage />
    }
    case 'cabasuite': {
      return <CabaSuitePage />
    }
    case 'paraliq': {
      return <ParaliqPage />
    }
    case 'mgc': {
      return <MGCPage />
    }
    case 'sba': {
      return <SBAPage />
    }
    case 'boomsite': {
      return <BoomSitePage />
    }
    case 'bigbro': {
      return <BigBroPage />
    }
    case 'brainspace': {
      return <BrainSpacePage />
    }
    case 'beyou': {
      return <BeYouPage />
    }
    case 'divanchik': {
      return <DivanchikPage />
    }
    default:
      break;
  }
}


const Portfolio = () => {
  const { id } = useParams();

  return (
    <div className='portfolio'>
      <PortfolioTemplate>
        {getTemplate(id)}
      </PortfolioTemplate>
    </div>
  );
};

export default Portfolio;