import './NotFound.css';

function NotFound() {
    return (
        <div className='not-found'>
            <p className='not-found__error'>404</p>
            <p className='not-found__title'>The Innovation Black Hole</p>
            <p className='not-found__text'>You&rsquo;ve reached the outer limits of&nbsp;our R&amp;D&nbsp;universe. Let&rsquo;s teleport you back to&nbsp;the epicenter of&nbsp;web development innovation.</p>
            <div class="bg-animation">
                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>
                <div id="stars4"></div>
            </div>
            <div class="black-hole"></div>
        </div>
    );
}

export default NotFound