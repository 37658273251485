import CompTemplate from '../CompTemplate/CompTemplate';
import './JellyBet.css';

import image from '../../../../assets/images/main/projects/jellybet.webp'
import useWindowSize from '../../../../assets/hooks/useWindowSize';

function JellyBet({ company }) {
    const windowSize = useWindowSize()
    return (
        <CompTemplate
            company={company}
            className={'jellybet-project-card'}
            project_name={'JELLY BET CASINO'}
            project_description={windowSize.width > 760 ? 'Decentralized Crypto casino website' : ''}
        >
            <div className='jellybet-project-card__content'>
                <div className='jellybet-project-card__bg'>
                    <div className='jellybet-project-card__bg-circle'></div>
                </div>
                <img className='jellybet-project-card__image' src={image} alt="" />
            </div>
        </CompTemplate>
    );
}

export default JellyBet