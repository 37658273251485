import useWindowSize from '../../../../assets/hooks/useWindowSize';
import './Mgc.css';
import CardTemplate from '../CardTemplate/CardTemplate';

function MgcLogoIcon({ className }) {
    return (
        <svg
            width={188}
            height={188}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox='0 0 188 188'
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.064 7.552l.81.81C41.822 21.307 43.17 29.94 43.17 40.189v98.18l31.555-31.557V32.907c0-7.822 0-14.296-11.328-25.624l-.808-.81-1.215-1.214-1.214-1.213s-.27 0-4.585-4.046H20.512l2.52 2.52.004.004c1.429 1.429 2.423 2.423 2.602 2.6.72.541 1.2 1.081 1.68 1.622.239.269.478.537.746.806zm96.56 49.903L181.805.273h-59.607c1.888 3.507 2.426 7.013 2.426 10.79v46.392zm63.375 8.627c-3.505-2.157-7.011-2.427-10.789-2.427h-46.391l57.18-57.182v59.61zm-6.206 59.07c.81.809 1.618 1.618 2.159 2.427 0 .176.345.583.885 1.219.287.338.628.741 1.003 1.209l2.157 2.427v34.525l-3.904-3.904-.951-.951c-.533-.801-1.334-1.603-2.134-2.404l-.023-.023-.81-.81c-12.946-12.946-21.576-14.025-31.827-14.295H50.173l31.557-31.558h73.632c7.822 0 14.565 0 25.623 11.329l.808.809zm-60.142-52.595h55.291c4.585.27 7.014.54 11.058 5.125v41.537l-.269-.269-.811-.809c-12.674-12.947-21.576-14.026-31.824-14.026H90.094l31.557-31.558zM83.887 32.906c0-10.25-1.08-19.15-14.025-31.828l-.54-.539-.269-.27h41.537c4.585 4.046 4.855 6.474 4.855 10.79v55.293L83.887 97.91V32.906zm-49.362 13.49v-6.203l-.016-.45c-.258-7.623-.476-14.063-11.853-25.174l-.808-.81c-.532-.53-1.321-1.32-1.86-1.86l-.028-.028c-.268-.266-.272-.27-5.712-5.71l-.007-.008-.215-.216L8.09.273H0V181.8l34.525-34.525V46.396zm140.558 120.549c.448.386.942.813 1.309 1.365.038.038.077.082.136.151.362.413 1.514 1.731 8.225 8.21l3.236 3.237V188H6.203l34.522-34.525H147.802c7.822.27 14.295.54 25.623 11.868l1.08 1.079c.171.171.369.343.578.523z"
                fill="#DFB521"
            />
        </svg>
    );
}

function Mgc({ company }) {

    const windowSize = useWindowSize()
    return (
        <CardTemplate
            company={company}
            className={'mgc-project-card'}
            project_name={'MGC PHARMA'}
            project_description={windowSize.width > 760 ? 'Full technical support of a new generation pharmaceutical company' : ''}
        >
            <div className='mgc-project-card__content'>
                <MgcLogoIcon className={'mgc-project-card__logo'} />
            </div>
        </CardTemplate>
    );
}

export default Mgc