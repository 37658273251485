import screen1 from '../../../assets/images/potrfolio/BoomSite/1.webp';
import screen2 from '../../../assets/images/potrfolio/BoomSite/2.webp';
import screen3 from '../../../assets/images/potrfolio/BoomSite/3.webp';
import screen4 from '../../../assets/images/potrfolio/BoomSite/4.webp';

import './BoomSitePage.css';

const BoomSitePage = () => {
    return (
        <>
            <img className='screen' alt='' src={screen1}></img>
            <img className='screen' alt='' src={screen2}></img>
            <img className='screen' alt='' src={screen3}></img>
            <img className='screen' alt='' src={screen4}></img>
        </>
    )
}
export default BoomSitePage