import './Docs.css';

function Docs({ text }) {
    return (
        <div className='docs'>
            <div className='docs__content' dangerouslySetInnerHTML={{
                __html: text
            }}>
            </div>
        </div>
    );
}

export default Docs