
import screen1 from '../../../assets/images/potrfolio/higgs/1.webp';
import screen3 from '../../../assets/images/potrfolio/higgs/2.webp';
import nft from '../../../assets/images/potrfolio/higgs/nft.webp';
import icon4 from '../../../assets/images/potrfolio/higgs/icon1.webp';
import icon5 from '../../../assets/images/potrfolio/higgs/icon2.webp';
import icon6 from '../../../assets/images/potrfolio/higgs/icon3.webp';


import './HiggsPage.css';

const HiggsPage = () => {

    return (
        <>
            <img className='screen' alt='' src={screen1}></img>
            <img className='screen' alt='' src={nft}></img>
            <div className='screen higgs__icon-box'>
                <img className='higgs__icon' alt='' src={icon4}></img>
                <img className='higgs__icon' alt='' src={icon5}></img>
                <img className='higgs__icon' alt='' src={icon6}></img>
            </div>
            <img className='screen' alt='' src={screen3}></img>
        </>
    )
}
export default HiggsPage