import card from '../../../../assets/images/potrfolio/paraliq/card.png'
import './Paraliq.css';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import CompTemplate from '../CompTemplate/CompTemplate';

function Paraliq({ company }) {
    const windowSize = useWindowSize()
    return (
        <CompTemplate
            company={company}
            className={'paraliq-project-card'}
            project_name={'Paraliq'}
            project_description={'Crypto games'}
        >
            <div className='paraliq-project-card__content'>
                <img className='paraliq-project-card__content__img' src={card} alt=''></img>
            </div>
        </CompTemplate>
    );
}

export default Paraliq;