import { Link, useLocation } from 'react-router-dom';
import { ShareIcon } from '../../../../assets/icons/icons';
import './CardTemplate.css';
import { ErrorBoundary } from 'react-error-boundary';
import NotFound from '../../../NotFound/NotFound';

function CardTemplate({
    className,
    children,
    project_name,
    project_description,
    company
}) {
    let location = useLocation();
    return (
        <ErrorBoundary FallbackComponent={<NotFound />}>

            <div className={`card-template ${className ? className : ''}`}>
                <Link to={`/${company}`} state={{ backgroundLocation: location }}>
                    <div className='card-template__content'>
                        {children}
                    </div>
                    {/* <div className='card-template__share'>
                <ShareIcon mainClassName={'card-template__share-icon'} />
            </div> */}
                    <div className='card-template__texts'>
                        <h4 className='card-template__name'>{project_name}</h4>
                        {project_description ?
                            <p className='card-template__description' dangerouslySetInnerHTML={{
                                __html: project_description,
                            }}></p>
                            : null}
                    </div>
                </Link>
            </div>
        </ErrorBoundary>
    );
}

export default CardTemplate