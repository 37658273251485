export const PRIVACY_POLICY_TEXT = `
    <h1>Privacy Policy</h1>
    <p><strong>Effective Date:</strong> 15.11.2024</p>
    <p>At <strong>Caba Hub</strong> ("we", "us", or "our"), we respect your privacy and are committed to protecting the personal data we collect from our users. This Privacy Policy outlines how we collect, use, and protect your information when you visit our website, use our services, or interact with us.</p>
    <p>By using our services, you agree to the collection and use of information in accordance with this policy. Please read it carefully to understand how we handle your personal data.</p>
    
    <h2>1. Information We Collect</h2>
    <p>We may collect the following types of personal information:</p>
    <ul>
        <li><strong>Personal Identification Information:</strong> Name, email address, phone number, etc.</li>
        <li><strong>Usage Data:</strong> Information on how you use our website or services.</li>
        <li><strong>Technical Data:</strong> IP address, browser type, device type, and other technical data.</li>
        <li><strong>Communication Data:</strong> Records of your interactions with us, including support tickets or inquiries.</li>
    </ul>
    
    <h2>2. How We Use Your Information</h2>
    <p>We use your personal information for various purposes, including:</p>
    <ul>
        <li>Providing, improving, and personalizing our services</li>
        <li>Responding to inquiries and customer support requests</li>
        <li>Sending administrative communications (e.g., updates, account management)</li>
        <li>Marketing and promotional activities (with your consent)</li>
        <li>Analyzing user activity to enhance user experience</li>
    </ul>
    
    <h2>3. Outsource Services</h2>
    <p>To help analyze and improve the data collected, we may use third-party services, including but not limited to analytics and marketing platforms. These services may access certain personal data but are prohibited from using it for other purposes. We ensure that all third-party services comply with applicable privacy laws.</p>
    
    <h2>4. Data Retention</h2>
    <p>We retain your personal data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
    
    <h2>5. Your Data Protection Rights</h2>
    <p>Depending on your location, you may have the right to request access to, correction, or deletion of your personal data. You can also object to or restrict the processing of your personal information in certain circumstances.</p>
    <p>For any data-related requests, please contact us at:</p>
    <ul>
        <li><strong>For urgent matters:</strong> <a href="mailto:info@cabahub.com">info@cabahub.com</a></li>
        <li><strong>For marketing and advertising inquiries:</strong> <a href="mailto:nv@cabahub.com">nv@cabahub.com</a></li>
        <li><strong>For general privacy-related inquiries:</strong> <a href="mailto:hello@cabahub.com">hello@cabahub.com</a></li>
    </ul>
    
    <h2>6. Security of Your Data</h2>
    <p>We use a variety of security measures to protect your personal data, including encryption and access control protocols. However, please be aware that no data transmission over the internet or electronic storage method is 100% secure.</p>
    
    <h2>7. Links to Third-Party Websites</h2>
    <p>Our website may contain links to third-party sites that are not operated by us. We have no control over and assume no responsibility for the content or privacy policies of third-party sites.</p>
    
    <h2>8. Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time. Any changes will be communicated through appropriate channels, and the updated version will be posted on our website. Please check this page periodically for updates.</p>
    
    <h2>9. Contact Us</h2>
    <p>If you have any questions about this Privacy Policy or need assistance regarding your personal data, we are at your disposal. Please feel free to reach out to us via the contact information provided on our website, and we will respond as promptly as possible to address your concerns.</p>
    <p>By using our services, you acknowledge that you have read and understood this Privacy Policy and agree to the collection and use of your data in accordance with it.</p>
`