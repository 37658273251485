import blue_bg from '../../../assets/images/potrfolio/pet4me/blue.svg';
import stick1 from '../../../assets/images/potrfolio/pet4me/popup1.webp';
import stick2 from '../../../assets/images/potrfolio/pet4me/popup2.webp';
import screen1 from '../../../assets/images/potrfolio/pet4me/1.webp';
import screen2 from '../../../assets/images/potrfolio/pet4me/2.webp';
import screen3 from '../../../assets/images/potrfolio/pet4me/3.webp';
import screen4 from '../../../assets/images/potrfolio/pet4me/4.webp';
import screen5 from '../../../assets/images/potrfolio/pet4me/5.webp';
import screen6 from '../../../assets/images/potrfolio/pet4me/6.webp';
import logo from '../../../assets/images/potrfolio/pet4me/logo.webp';
import last from '../../../assets/images/potrfolio/pet4me/last-stick.svg';
import stick from '../../../assets/images/potrfolio/pet4me/roadmap.webp';

import './Pet4MePage.css';

const Pet4MePage = () => {

    return (
        <>
            <img className='pet4me__logo' alt='' src={logo}></img>
            <img className='pet4me__roadmap' alt='' src={stick}></img>
            <div className='pet4me__screen-box_small'>
                <img className='pet4me__popup' alt='' src={stick1}></img>
                <img className=' pet4me__popup' alt='' src={stick2}></img>
                <img className='pet4me__bg_blue' alt='' src={blue_bg}></img>
            </div>
            <img className='screen' alt='' src={screen1}></img>
            <img className='screen' alt='' src={screen2}></img>
            <img className='screen' alt='' src={screen3}></img>
            <img className='screen' alt='' src={screen4}></img>
            <img className='screen' alt='' src={screen5}></img>
            <img className='screen' alt='' src={screen6}></img>
            <img className='pet4me__last-sticker' alt='' src={last}></img>
        </>
    )
}
export default Pet4MePage