import './ParaliqPage.css';
import card1 from '../../../assets/images/potrfolio/paraliq/Card-1.webp'
import card2 from '../../../assets/images/potrfolio/paraliq/Card-2.png'
import card3 from '../../../assets/images/potrfolio/paraliq/Card-3.png'
import card4 from '../../../assets/images/potrfolio/paraliq/Card-4.png'
import card5 from '../../../assets/images/potrfolio/paraliq/Card-5.png'
import card6 from '../../../assets/images/potrfolio/paraliq/Card-6.png'
import card7 from '../../../assets/images/potrfolio/paraliq/Card-7.png'
import card8 from '../../../assets/images/potrfolio/paraliq/Card-8.png'
import card9 from '../../../assets/images/potrfolio/paraliq/Card-9.png'
import shuttle from '../../../assets/images/potrfolio/paraliq/shuttle.webp'
import dragon from '../../../assets/images/potrfolio/paraliq/dragon.webp'
import popper from '../../../assets/images/potrfolio/paraliq/popper.webp'
import seven from '../../../assets/images/potrfolio/paraliq/sevens.webp'
import charger from '../../../assets/images/potrfolio/paraliq/charger.webp'
import screen1 from '../../../assets/images/potrfolio/paraliq/1.webp'
import screen2 from '../../../assets/images/potrfolio/paraliq/2.webp'
import screen3 from '../../../assets/images/potrfolio/paraliq/3.webp'
import screen4 from '../../../assets/images/potrfolio/paraliq/4.webp'
import screen5 from '../../../assets/images/potrfolio/paraliq/5.webp'
import last from '../../../assets/images/potrfolio/paraliq/last.webp'
import canfetti from '../../../assets/images/potrfolio/paraliq/canfetti.png'
import bg from '../../../assets/images/potrfolio/paraliq/bgBig.png'
const ParaliqPage = () => {

    return (
        <div style={{ position: 'relative', margin: '0 auto' }}>
            <div className='paraliq-page__card-grid'>
                <img alt='' className='paraliq-page__card' style={{gridArea:'card1'}} src={card1}></img>
                <img alt='' className='paraliq-page__card' style={{gridArea:'card2'}} src={card2}></img>
                <img alt='' className='paraliq-page__card' style={{gridArea:'card3'}} src={card3}></img>
                <img alt='' className='paraliq-page__card' style={{gridArea:'card4'}} src={card4}></img>
                <img alt='' className='paraliq-page__card' style={{gridArea:'card5'}} src={card5}></img>
                <img alt='' className='paraliq-page__card' style={{gridArea:'card6'}} src={card6}></img>
                <img alt='' className='paraliq-page__card' style={{gridArea:'card7'}} src={card7}></img>
                <img alt='' className='paraliq-page__card' style={{gridArea:'card8'}} src={card8}></img>
                <img alt='' className='paraliq-page__card' style={{gridArea:'card9'}} src={card9}></img>
            </div>
            <img className='paraliq-page__bg paraliq-page__bg_big' src={bg} alt=''></img>
            <div className='paraliq-page__game-title_to-right'>
                <img className='paraliq-page__game-title__logo paraliq-page__game-title__logo_shuttle' alt='' src={shuttle}></img>
                <div className='paraliq-page__game-title'>
                    <p className='paraliq-page__game-title__name paraliq-page__game-title__name_shuttle'>Shuttle</p>
                    <p className='paraliq-page__game-title__discription'>How long will the shuttle stay in flight? Place your bet and win if the shuttle stays in for that long or more</p>
                </div>
            </div>
            <img className='screen' alt='' src={screen1}></img>
            <div className='paraliq-page__game-title_to-left'>
                <div className='paraliq-page__game-title'>
                    <p className='paraliq-page__game-title__name paraliq-page__game-title__name_dragon'>Dragon</p>
                    <p className='paraliq-page__game-title__discription'>Follow the dragon's flight and guess how long it will take to the end. Will it ever end though? Play to find out and win</p>
                </div>
                <img className='paraliq-page__game-title__logo paraliq-page__game-title__logo_dragon' alt='' src={dragon}></img>
            </div>
            <img className='screen' alt='' src={screen2}></img>
            <div className='paraliq-page__game-title_to-right'>
                <img className='paraliq-page__game-title__logo paraliq-page__game-title__logo_popper' alt='' src={popper}></img>
                <div className='paraliq-page__game-title'>
                    <p className='paraliq-page__game-title__name paraliq-page__game-title__name_popper'>Party Popper</p>
                    <p className='paraliq-page__game-title__discription'>The flapper is about to burst. Can you guess how much you need to pull out the tail for this? Turn on your intuition!</p>
                </div>
            </div>
            <img className='screen' alt='' src={screen3}></img>
            <div className='paraliq-page__game-title_to-left'>
                <div className='paraliq-page__game-title paraliq-page__game-title_seven'>
                    <p className='paraliq-page__game-title__name paraliq-page__game-title__name_seven'>Lucky Sevens</p>
                    <p className='paraliq-page__game-title__discription'>Everyone knows that seven is a lucky number. Will it bring good luck to you? Bet on red or green: one, two or three — and win.</p>
                </div>
                <img className='paraliq-page__game-title__logo paraliq-page__game-title__logo_seven' alt='' src={seven}></img>
            </div>
            <img className='screen' alt='' src={screen4}></img>
            <div className='paraliq-page__game-title_to-right'>
                <img className='paraliq-page__game-title__logo paraliq-page__game-title__logo_charger' alt='' src={charger}></img>
                <div className='paraliq-page__game-title'>
                    <p className='paraliq-page__game-title__name paraliq-page__game-title__name_charger'>Charger</p>
                    <p className='paraliq-page__game-title__discription'>It's always important to know how much charging is left, isn't it? Hit the bull's-eye and become a winner!</p>
                </div>
            </div>
            <img className='screen' alt='' src={screen5}></img>
            <div className='paraliq-page__win-block'>
            <img className='screen' alt='' src={last}></img>
            <img className='paraliq-page__win-block__bg' alt='' src={canfetti}></img>
            </div>
        </div>
    )
}
export default ParaliqPage