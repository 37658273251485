import React from 'react';
import './WhiteLabel.css';
import { WL_MAIN_TEXT, WL_CARDS_DATA } from '../../assets/utils/wlConstants';
import WL_MAIN_ICON from '../../assets/images/wl/ma.webp';
import WhiteLabelCard from './Card/wlCard';
import ContactUs from '../Main/ContactUs/ContactUs';

export default function WhiteLabel() {
  return (
    <div className="wl">
      <div className="wl__description">
        <h1 className="wl__description-heading">White Label Solutions</h1>
        <p
          className="wl__description-text"
          dangerouslySetInnerHTML={{ __html: WL_MAIN_TEXT }}
        />
      </div>

      <ul className="wl__cards">
        {WL_CARDS_DATA.map((card) => (
          <WhiteLabelCard key={card.id} {...{ card }} />
        ))}
      </ul>

      <div className="wl__slogan">
        <img className="wl__slogan-icon" src={WL_MAIN_ICON} alt="" />
        <p className="wl__slogan-title">
          <span className="wl__slogan-title_type_color">Creativity</span>{' '}
          is&nbsp;a&nbsp;way to&nbsp;Think More Efficiently
        </p>
      </div>

      <div className="wl__contact-block">
        <ContactUs />
      </div>
    </div>
  );
}
