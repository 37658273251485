import './Contacts.css';

import bg from '../../assets/images/main/banner/main-banner-bg.webp'
import bg_mobile from '../../assets/images/main/banner/main-banner-bg-mobile.webp'
import circle_bg from '../../assets/images/main/bg2.webp'
import ContactUs from '../Main/ContactUs/ContactUs';
import { CONTACT_LINKS } from '../../assets/utils/constants';
function Contacts() {
    return (
        <div className='contacts'>
            <div className='contacts__title'>
                <p className='contacts__title__text'>contacts</p>
                <img className='banner__bg banner__bg_pc' src={bg} alt=""></img>
                <img className='banner__bg banner__bg_mobile' src={bg_mobile} alt=""></img>
            </div>
            <div className='contacts__text__box'>

                <div className='contacts__social__box'>
                    {CONTACT_LINKS.map((item) => {
                        return (
                            <div className='contacts__social'>
                                <img alt='' className='contacts__social__logo' src={item.logo}></img>
                                <img alt='' className='contacts__social__logo_mobile' src={item.logo_mobile}></img>
                                <a href={item.path} className='contacts__social__text' target='_blank' rel='noreferrer'>
                                    <p className='contacts__social__text__name'>{item.name}</p>
                                    <p className='contacts__social__text__link'>{item.link_name}</p>
                                </a>
                            </div>
                        )
                    })}
                </div>
                <ContactUs />
                <img className='contacts__bg_circle' alt='' src={circle_bg}></img>
            </div>
        </div>
    );
}

export default Contacts