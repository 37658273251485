
import { useLocation, Link } from "react-router-dom";
import './OutsideModalCloser.css';

const OutsideModalCloser = () => {
  const { state } = useLocation();
  const isInsideModal = state?.backgroundLocation;
 
  return (
    <div >
    {/*  {!isInsideModal ? (

          <Link to={'/cases'} className="button__show-all-projects">ALL PROJECTS</Link>
        
    ) : null}*/}
    </div>
  );
};

export default OutsideModalCloser;