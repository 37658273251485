
import { useState } from 'react';
import CardTemplate from '../CardTemplate/CardTemplate';
import './CompTemplate.css';

import mac_desktop from '../../../../assets/images/main/projects/macos-big-sur-wallpaper-2-scaled.webp'
import { LogoIcon } from '../../../../assets/icons/icons';


function CompTemplate({
    children,
    className,
    project_name,
    project_description,
    company
}) {

    const [isClosed, setClosed] = useState(false)


    return (
        <CardTemplate
            className={className}
            project_name={project_name}
            project_description={project_description}
            company={company}
        >
            {!isClosed ?
                <div className={`comp-template`}>

                    <div className='comp-template__content'>
                        {children}
                    </div>
                    <div className='comp-template__gui'>
                        <div className='comp-template__gui-btn comp-template__gui-btn_type_red' onClick={() => { setClosed(true) }}></div>
                        <div className='comp-template__gui-btn comp-template__gui-btn_type_yellow'></div>
                        <div className='comp-template__gui-btn comp-template__gui-btn_type_green'></div>
                    </div>
                </div>
                :
                <div className='comp-template__desctop'>
                    <img className='comp-template__desctop-bg' src={mac_desktop} alt='' />
                    <div className='comp-template__desctop-caba-app' onClick={() => { setClosed(false) }}>
                        <LogoIcon mainClassName={'comp-template__desctop-caba-app-logo'} />
                    </div>
                </div>
            }
        </CardTemplate>

    );
}

export default CompTemplate