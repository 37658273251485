import app from '../../../assets/images/potrfolio/review/app.webp';
import book from '../../../assets/images/potrfolio/review/book.webp';
import screen1 from '../../../assets/images/potrfolio/review/1.webp';
import screen2 from '../../../assets/images/potrfolio/review/2.webp';
import screen3 from '../../../assets/images/potrfolio/review/3.webp';
import screen4 from '../../../assets/images/potrfolio/review/4.webp';
import screen5 from '../../../assets/images/potrfolio/review/5.webp';
import screen6 from '../../../assets/images/potrfolio/review/6.webp';
import camera from '../../../assets/images/potrfolio/review/camera.webp';
import info from '../../../assets/images/potrfolio/review/info.webp';
import './OpenReviewPage.css';

const OpenReviewPage = () => {

  return (
    <>
      <img className='screen review__screen review__screen_app-img' alt='' src={app}></img>
      <img className='screen review__screen review__screen_book-img ' alt='' src={book}></img>

      <div className='review__grid'>
        <img style={{ gridArea: 'screen1' }} className='review__grid__screen' alt='' src={screen1}></img>
        <img style={{ gridArea: 'camera' }} className='review__screen_camera' alt='' src={camera}></img>
        <img style={{ gridArea: 'screen2' }} className='review__grid__screen' alt='' src={screen2}></img>
        <img style={{ gridArea: 'screen3' }} className='review__grid__screen' alt='' src={screen3}></img>
        <img style={{ gridArea: 'screen4' }} className='review__grid__screen' alt='' src={screen4}></img>
        <img style={{ gridArea: 'screen5' }} className='review__grid__screen' alt='' src={screen5}></img>
        <img style={{ gridArea: 'info' }} className='review__screen_info' alt='' src={info}></img>
        <img style={{ gridArea: 'screen6' }} className='review__grid__screen' alt='' src={screen6}></img>
      </div>
    </>

  )
}
export default OpenReviewPage