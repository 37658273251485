import CabaSuite from '../Main/Projects/CabaSuite/CabaSuite';
import Guestme from '../Main/Projects/Guestme/Guestme';
import HiggsField from '../Main/Projects/HiggsField/HiggsField';
import JellyBet from '../Main/Projects/JellyBet/JellyBet';
import Mgc from '../Main/Projects/Mgc/Mgc';
import OpenReview from '../Main/Projects/OpenReview/OpenReview';
import Pet4Me from '../Main/Projects/Pet4Me/Pet4Me';
import Sputnik from '../Main/Projects/Sputnik/Sputnik';
import Zam from '../Main/Projects/Zam/Zam';
import './AllProjects.css';

import bg from '../../assets/images/main/banner/main-banner-bg.webp'
import bg_mobile from '../../assets/images/main/banner/main-banner-bg-mobile.webp'
import MetaMedix from '../Main/Projects/MetaMedix/MetaMedix';
import Paraliq from '../Main/Projects/Paraliq/Paraliq';
import SBA from '../Main/Projects/SBA/SBA';
import BoomSite from '../Main/Projects/BoomSite/BoomSite';
import BigBro from '../Main/Projects/BigBro/BigBro';
import BrainSpace from '../Main/Projects/BrainSpace/BrainSpace';
import BeYou from '../Main/Projects/BeYou/BeYou';
import Divanchik from '../Main/Projects/Divanchik/Divanchik';

function AllProjects() {
    return (
        <div className='all-projects'>
            <div className='all-projects__title'>
                <p className='all-projects__title__text'>Top cases</p>
                <img className='banner__bg banner__bg_pc' src={bg} alt=""></img>
                <img className='banner__bg banner__bg_mobile' src={bg_mobile} alt=""></img>
            </div>
            <div className='all-projects__grid'>
                <Zam company={'cases/zam'} />
                <Sputnik company={'cases/sputnik'} />
                <OpenReview company={'cases/openreview'} />
                <JellyBet company={'cases/jellybet'} />
                <HiggsField company={'cases/higgs'} />
                <Pet4Me company={'cases/pet4me'} />
                <Mgc company={'cases/mgc'} />
                <Guestme company={'cases/guestme'} />
                <MetaMedix company={'cases/metamedix'} />
                <CabaSuite company={'cases/cabasuite'} />
                <Paraliq company={'cases/paraliq'}/>
                <SBA company={'cases/sba'}/>
                <BoomSite company={'cases/boomsite'} />
                <BigBro company={'cases/bigbro'} />
                <BrainSpace company={'cases/brainspace'} />
                <BeYou company={'cases/beyou'} />
                <Divanchik company={'cases/divanchik'} />
            </div>
            <p className='all-projects__more'>And more</p>
        </div>
    );
}

export default AllProjects