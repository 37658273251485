import card from '../../../../assets/images/potrfolio/bigbro/bigbro_card_bg.webp'
import './BigBro.css';
import { Link, useLocation } from 'react-router-dom';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import CompTemplate from '../CompTemplate/CompTemplate';

function BigBro({company}) {
    const windowSize = useWindowSize()
    let location = useLocation();
    return (
        <CompTemplate
            company={company}
            className={'bigbro-project-card'}
            project_name={'BIG BRO PRO'}
            project_description={'Stylish events in Moscow'}
        >
             <Link to={`/${company}`} state={{ backgroundLocation: location }}>
            <div className='bigbro-project-card__content'>
              <img className='bigbro-project-card__content__img' src={card} alt=''></img>
            </div>
            </Link>
        </CompTemplate>
    );
}

export default BigBro;