
import logo from '../../../assets/images/potrfolio/zam/mascot.png';
import pc from '../../../assets/images/potrfolio/zam/pc.png';
import pills from '../../../assets/images/potrfolio/zam/pills.svg';
import popup1 from '../../../assets/images/potrfolio/zam/popup1.svg';
import popup2 from '../../../assets/images/potrfolio/zam/popup2.svg';

import popup3 from '../../../assets/images/potrfolio/zam/popup3.svg';
import popup4 from '../../../assets/images/potrfolio/zam/popup4.svg';
import doctor from '../../../assets/images/potrfolio/zam/doctor.svg';

import cab1 from '../../../assets/images/potrfolio/zam/cab1.svg';
import cab2 from '../../../assets/images/potrfolio/zam/cab1.svg';

import line from '../../../assets/images/potrfolio/zam/green-line.svg';
import shortline from '../../../assets/images/potrfolio/zam/short-green-line.svg';
import history from '../../../assets/images/potrfolio/zam/history.webp';
import popup5 from '../../../assets/images/potrfolio/zam/popup5.svg';
import popup6 from '../../../assets/images/potrfolio/zam/popup6.svg';
import circle from '../../../assets/images/potrfolio/zam/circle.svg';

import './ZamPage.css';

const ZamPage = () => {

    return (
        <>
            <img className='screen zam__screen-mascot' alt='' src={logo}></img>
            <img className='screen zam__screen zam__screen-pc' alt='' src={pc}></img>
            <div className='zam__grid zam__grid_first screen zam__screen'>
                <img style={{gridArea:'popup1'}} className='zam__img zam__img_small' alt='' src={popup1}></img>
                <img style={{gridArea:'popup2'}} className='zam__img zam__img_small' alt='' src={popup2}></img>
                <img style={{gridArea:'pills'}} className='zam__img zam__img_big' alt='' src={pills}></img>
            </div>
            <div className='zam__grid zam__grid_second screen zam__screen'>
                <img style={{gridArea:'popup1'}} className='zam__img zam__img_small' alt='' src={popup3}></img>
                <img style={{gridArea:'popup2'}} className='zam__img zam__img_small' alt='' src={popup4}></img>
                <img style={{gridArea:'pills'}} className='zam__img zam__img_big' alt='' src={doctor}></img>
                <img className='zam__circle-green' alt='' src={circle}></img>
            </div>
            <div className='zam__grid zam__grid_third screen zam__screen'>
                <img style={{gridArea:'popup1'}} className='' alt='zam__img zam__img_middle' src={cab1}></img>
                <img style={{gridArea:'popup2'}} className='' alt='zam__img zam__img_middle' src={cab2}></img>
            </div>
            <img className='zam__screen screen zam__img zam__line' alt='' src={line}></img>
            <img className='zam__screen screen zam__img zam__line_short' alt='' src={shortline}></img>
            <div className='zam__grid zam__grid_first screen zam__screen'>
                <img style={{gridArea:'popup1'}} className='zam__img zam__img_small' alt='' src={popup5}></img>
                <img style={{gridArea:'popup2'}} className='zam__img zam__img_small' alt='' src={popup6}></img>
                <img style={{gridArea:'pills'}} className='zam__img zam__img_big' alt='' src={history}></img>
            </div>
        </>
    )
}
export default ZamPage