import { Link } from 'react-router-dom';
import './ProjectsHeading.css';

function ProjectsHeading() {
    return (
        <div className='projects-heading'>
            <h3 className='projects-heading__title'><span className='projects-heading__title-span'>Creativity</span> <br className='projects-heading__title-br_type_mobile' />is&nbsp;a&nbsp;way <br className='projects-heading__title-br_type_pc' />to&nbsp;Think <br className='projects-heading__title-br_type_mobile' />More Efficiently</h3>
            <div className='projects-heading__line__box'>
                <div className='projects-heading__arrow-box'>
                    <div className='projects-heading__arrow-line'></div>
                    <div className='projects-heading__arrow-corner'></div>
                </div>
                <Link to={'/cases'} className="button__show-all-projects">ALL PROJECTS</Link>
            </div>
            {/* <Button
                isBgContrast={true}
                text={'ALL PROJECTS'}
                className={'projects-heading__btn projects-heading__btn_pc'}
            /> */}
            {/* <div></div> */}
            <div className='projects-heading__btn-and-arrow'>
                {/* <Button
                    isBgContrast={true}
                    text={'ALL PROJECTS'}
                    className={'projects-heading__btn-mobile'}
                /> */}
                {/* <div></div> */}
                <div className='projects-heading__arrow-box'>
                    <div className='projects-heading__arrow-line'></div>
                    <div className='projects-heading__arrow-corner'></div>
                </div>

            </div>
        </div>
    );
}

export default ProjectsHeading