import { useState } from 'react';
import { Link } from 'react-router-dom';
import ServicesCard from './Card/ServicesCard';
import Stats from '../Main/Stats/Stats';
import ContactUs from '../Main/ContactUs/ContactUs';
import {
  SERVICES_MAIN_DESCRIPTION,
  SERVICES_CARDS_DATA,
} from '../../assets/utils/servicesConsts';
import { RoundArrowIcon } from '../../assets/icons/icons';
import SERVICES_MAIN_IMG from '../../assets/images/services/services-main-img.webp';
import newbg from '../../assets/images/services/newbg.webp';
import './Services.css';

export default function Services() {
  const [openCard, setOpenCard] = useState('');

  function handleOpen(id) {
    setOpenCard(id);
  }

  function handleClose(evt) {
    evt.stopPropagation();
    setOpenCard('');
  }

  return (
    <div className="services">
      <div className="services__description">
        <div className="services__description-box">
          <h1 className="services__description-heading">
            Services <span className="services__description-heading-dash" />
          </h1>
          <p
            className="services__description-text"
            dangerouslySetInnerHTML={{ __html: SERVICES_MAIN_DESCRIPTION }}
          />
        </div>
        <img
          className="services__description-img"
          alt=""
          src={SERVICES_MAIN_IMG}
        />
      </div>

      <ul className="services__cards">
        {SERVICES_CARDS_DATA.map((card) => (
          <ServicesCard
            key={card.id}
            isOpen={openCard === card.id}
            isDisabled={Boolean(openCard) && openCard !== card.id}
            onOpen={handleOpen}
            onClose={handleClose}
            {...{ card }}
          />
        ))}
        <Link className="services__card-link" to="/cases">
          <RoundArrowIcon
            mainClassName="services__link-arrow"
            fillClassName="services__link-arrow-fill"
          />
          <p className="services__link-text">view cases</p>
        </Link>
      </ul>

      <img alt="" src={newbg} className="services__bg" />
      <Stats />
      <ContactUs />
    </div>
  );
}
