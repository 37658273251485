import card from '../../../../assets/images/potrfolio/SBA/SBA-bg.webp'
import './SBA.css';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import CompTemplate from '../CompTemplate/CompTemplate';

function SBA({ company }) {
    const windowSize = useWindowSize()
    return (
        <CompTemplate
            company={company}
            className={'sba-project-card'}
            project_name={'SBA'}
            project_description={'Brazilian Jiu-Jitsu Federation'}
        >
            <div className='sba-project-card__content'>
                <img className='sba-project-card__content__img' src={card} alt=''></img>
            </div>
        </CompTemplate>
    );
}

export default SBA;