
import logo from '../../../assets/images/potrfolio/cabasuite/folder.png';
import screen1 from '../../../assets/images/potrfolio/cabasuite/1.webp';
import screen2 from '../../../assets/images/potrfolio/cabasuite/2.webp';
import screen3 from '../../../assets/images/potrfolio/cabasuite/3.webp';
import screen4 from '../../../assets/images/potrfolio/cabasuite/4.webp';
import screen5 from '../../../assets/images/potrfolio/cabasuite/5.webp';
import screen6 from '../../../assets/images/potrfolio/cabasuite/6.webp';
import screen7 from '../../../assets/images/potrfolio/cabasuite/7.webp';
import bg1 from '../../../assets/images/potrfolio/cabasuite/bg1.png';
import bg2 from '../../../assets/images/potrfolio/cabasuite/bg2.png';
import bg3 from '../../../assets/images/potrfolio/cabasuite/bg3.png';
import popup1 from '../../../assets/images/potrfolio/cabasuite/popup1.webp';
import popup2 from '../../../assets/images/potrfolio/cabasuite/popup2.webp';
import popup3 from '../../../assets/images/potrfolio/cabasuite/popup3.webp';
import key from '../../../assets/images/potrfolio/cabasuite/key.webp';
import './CabaSuitePage.css';

const CabaSuitePage = () => {

    return (
        <>
            <img className='cabasuite__folder' alt='' src={logo}></img>
            <div className='screen cabasuite__bg'>
                <img className='screen cabasuite__screen' alt='' src={screen1}></img>
                <img className='cabasuite__bg_first' alt='' src={bg1}></img>
            </div>
            <img className='screen cabasuite__screen' alt='' src={screen2}></img>
            <img className='screen cabasuite__screen' alt='' src={screen3}></img>
            <div className='screen cabasuite__bg'>
            <img className='screen cabasuite__screen' alt='' src={screen4}></img>
            <img className='cabasuite__bg_second' alt='' src={bg2}></img>
            </div>
            <img className='screen cabasuite__screen' alt='' src={screen5}></img>
            <img className='screen cabasuite__screen' alt='' src={screen6}></img>
            <div className='screen cabasuite__bg'>
            <img className='screen cabasuite__screen' alt='' src={screen7}></img>
            <img className='cabasuite__bg_third' alt='' src={bg3}></img>
            </div>
            <div className='cabasuite__popup__box'>
            <img className='cabasuite__popup' alt='' src={popup1}></img>
            <img className='cabasuite__popup' alt='' src={popup2}></img>
            <img className='cabasuite__popup' alt='' src={popup3}></img>
            <img className='cabasuite__key' alt='' src={key}></img>
            </div>
        </>
    )
}
export default CabaSuitePage