
import './Banner.css';

import bg from '../../../assets/images/main/banner/main-banner-bg.webp'
import bg_mobile from '../../../assets/images/main/banner/main-banner-bg-mobile.webp'


import { LineIcon, LogoIcon } from '../../../assets/icons/icons';
import { CONTACT_LINKS } from '../../../assets/utils/constants';
import { sendMetriс } from '../../../assets/utils/utils';

function Banner() {
    return (
        <div className="banner">
            <img className='banner__bg banner__bg_pc' src={bg} alt=""></img>
            <img className='banner__bg banner__bg_mobile' src={bg_mobile} alt=""></img>

            <div className='banner__main'>
                <div className='banner__logo-and-line'>
                    <LogoIcon mainClassName={'banner__logo'} />
                    <LineIcon mainClassName={'banner__line'} />
                </div>

                <div className='banner__main-texts'>
                    <h2 className='banner__title'>WE&nbsp;COMBINE RICH&nbsp;<br className='banner__title-br' />TECHNOLOGY TO&nbsp;CRAFT INNOVATIVE SOLUTIONS</h2>
                    <div className='banner__title-dot'></div>
                    <p className='banner__text'>We&rsquo;re full service which means we&rsquo;ve got you covered on&nbsp;design &amp;&nbsp;content right through to&nbsp;digital. You&rsquo;ll form a&nbsp;lasting relationship with&nbsp;us, collaboration is&nbsp;central to&nbsp;what we&nbsp;do. We&nbsp;founded Caba Hub with the goal of&nbsp;creating meaningful digital experiences that connect with people. We&nbsp;specialize in&nbsp;creating start-up MVP&rsquo;s, design, brand development, web app development, cybersecurity, tech support, and consulting.</p>
                </div>
            </div>
            <div className='banner__contact'>
                <div className='banner__contact-links'>
                    {CONTACT_LINKS.map((item, i) => (
                        <a className='banner__contact-link' href={item.path} target="_blank" rel='noreferrer' key={`mainBannerContactLink${i}`} onClick={() => {
                            sendMetriс('reachGoal', item.goal_id)
                        }}>
                            {item.Icon({ mainClassName: 'banner__contact-link-icon', strokeClassName: item.isContrast ? 'banner__contact-link-icon-stroke banner__contact-link-icon-stroke_type_contrast' : 'banner__contact-link-icon-stroke', fillClassName: item.isContrast ? 'banner__contact-link-icon-fill banner__contact-link-icon-fill_type_contrast' : 'banner__contact-link-icon-fill' })}
                        </a>
                    ))}
                </div>
                <p className='banner__contact-text'>Contact Us</p>
            </div>
        </div>
    );
}

export default Banner;
