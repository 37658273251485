import { PROJECT_NUMBERS } from "../../../assets/utils/constants";

import './Numbers.css';




function Numbers() {
    return (
        <div className='numbers'>
            {PROJECT_NUMBERS.map((item, i) => (
                <div className={`numbers__card numbers__card_type_${i + 1}`}>
                    <p className="numbers__card-title">{item.title}</p>
                    <p className="numbers__card-count">{item.count}</p>
                    {item.icon({ mainClassName: 'numbers__card-icon' })}
                </div>
            ))}
        </div>
    );
}

export default Numbers