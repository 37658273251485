import DESIGN_IMG from '../images/services/0.webp'
import WEB_AND_PLATFORM_IMG from '../images/services/1.webp'
import APPLICATIONS_IMG from '../images/services/2.webp'
import CRYPTO_IMG from '../images/services/3.webp'
import DIGITAL_STRATEGY_IMG from '../images/services/4.webp'


export const SERVICES_MAIN_DESCRIPTION = 'Each service is&nbsp;crafted to&nbsp;not only meet the current demands of&nbsp;the market but also to&nbsp;provide exceptional value and distinctive advantages to&nbsp;clients, ensuring that Caba Hub remains a&nbsp;leader in&nbsp;the digital solutions space.';


export const SERVICES_CARDS_DATA = [
    {
        id: 1,
        heading: 'design',
        img: DESIGN_IMG,
        cardBackground: 'bg-null',
        text: 'Elevate your brand with our dynamic UX/UI design and branding services. We&nbsp;specialize in&nbsp;creating impactful user interfaces and compelling brand identities, including logos and visual elements.',
        projects: 441,
    },
    {
        id: 2,
        heading: 'website &amp;&nbsp;platform',
        img: WEB_AND_PLATFORM_IMG,
        cardBackground: 'bg-one',
        text: 'Transform your digital footprint with our state-of-the-art Website &amp;&nbsp;Platform Development service. We&rsquo;re dedicated to&nbsp;harnessing the newest technologies to&nbsp;build websites and platforms that are advanced, user-centric and trendsetting.',
        projects: 388,
    },
    {
        id: 3,
        heading: 'applications',
        img: APPLICATIONS_IMG,
        cardBackground: 'bg-two',
        text: 'Elevate your digital strategy with our premier Application Development Service. We&nbsp;craft high-performance, custom apps that surpass market demands through innovation and user-centric design.',
        projects: 45,
    },
    {
        id: 4,
        heading: 'crypto',
        img: CRYPTO_IMG,
        cardBackground: 'bg-three',
        text: 'Dive into the world of cryptocurrencies with our comprehensive crypto services. From blockchain solutions to&nbsp;crypto wallet development we&nbsp;cover you from A-Z.',
        projects: 31,
    },
    {
        id: 5,
        heading: 'digital strategy &amp; <br /> consulting',
        img: DIGITAL_STRATEGY_IMG,
        cardBackground: 'bg-four',
        text: 'Gain a&nbsp;competitive edge with our personalized, data-driven strategies and insights, ensuring your digital efforts align perfectly with your unique business goals and objectives.',
        projects: 44,
    },

]