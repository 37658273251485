import PhoneTemplate from '../PhoneTemplate/PhoneTemplate';
import './Zam.css';

import mascot from '../../../../assets/images/main/projects/zam-mascot.webp'
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import { useLocation } from 'react-router-dom';

function Zam({ company }) {
    const windowSize = useWindowSize()
    return (
        <PhoneTemplate
            className={'zam-project-card'}
            project_name={'ZAM'}
            company={company}
            project_description={windowSize.width > 760 ? 'Medication control app' : ''}
        >
                <div className='zam-project-card__content'>
                    <div className='zam-project-card__bg-circles'>
                        <div className='zam-project-card__bg-circle-1'></div>
                        <div className='zam-project-card__bg-circle-2'></div>
                    </div>
                    <img className='zam-project-card__mascot' src={mascot} alt="" />
                </div>
        </PhoneTemplate>
    );
}

export default Zam