import './MetaMedix.css';
import card from '../../../../assets/images/potrfolio/metamedix/card.png'
import CardTemplate from '../CardTemplate/CardTemplate';

function MetaMedix({ company }) {
    return (
        <CardTemplate
            company={company}
            className={'metamedix-project-card'}
            project_name={'metamedix'}
            project_description={'Pharmaceutical company supplying plant-inspired medication'}
        >
            <div className='metamedix-project-card__content'>
                <img className='metamedix-project-card__content__img' src={card} alt=''></img>
            </div>
        </CardTemplate>
    );
}

export default MetaMedix