import screen from '../../../assets/images/potrfolio/bigbro/Screen-1.webp';
import screen2 from '../../../assets/images/potrfolio/bigbro/Screen-2.webp';
import screen3 from '../../../assets/images/potrfolio/bigbro/Screen-3.webp';
import screen4 from '../../../assets/images/potrfolio/bigbro/Screen-4.webp';
import screen5 from '../../../assets/images/potrfolio/bigbro/Screen-5.webp';
import stick1 from '../../../assets/images/potrfolio/bigbro/stick-1.1.webp';
import stick2 from '../../../assets/images/potrfolio/bigbro/stick2.webp';
import stick3 from '../../../assets/images/potrfolio/bigbro/stick3.webp';
import stick4 from '../../../assets/images/potrfolio/bigbro/stick4.webp';
import stick5 from '../../../assets/images/potrfolio/bigbro/stick5.webp';
import stick6 from '../../../assets/images/potrfolio/bigbro/stick6.webp';
import stick7 from '../../../assets/images/potrfolio/bigbro/stick7.webp';
import stick8 from '../../../assets/images/potrfolio/bigbro/stick8.webp';
import stick9 from '../../../assets/images/potrfolio/bigbro/stick9.webp';
import stick10 from '../../../assets/images/potrfolio/bigbro/stick10.webp';
import './BigBroPage.css';

const BigBroPage = () => {
    return (
        <>
            <img className='screen' alt='' src={screen}></img>
            <img className='screen' alt='' src={screen2}></img>
            <img className='screen' alt='' src={screen3}></img>
            <img className='screen' alt='' src={screen4}></img>
            <img className='main-stick' alt='' src={stick1}></img>
            <div className='stick-block'>
                <img className='stick' alt='' src={stick2}></img>
                <img className='stick' alt='' src={stick3}></img>
                <img className='stick' alt='' src={stick5}></img>
                <img className='stick' alt='' src={stick4}></img>
                <img className='stick' alt='' src={stick7}></img>
                <img className='stick' alt='' src={stick8}></img>
                <img className='stick' alt='' src={stick6}></img>
                <img className='stick' alt='' src={stick9}></img>
                <img className='last-stick last-stick-mobile' alt='' src={stick10}></img>
            </div>
            <img className='last-stick last-stick-desktop' alt='' src={stick10}></img>
            <img className='screen' alt='' src={screen5}></img>
        </>

    )
}
export default BigBroPage