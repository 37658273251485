
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Navigation, Pagination } from 'swiper/modules';


import "swiper/css";

import './Clients.css';
import { CLIENTS_CARDS, ZamIcon } from "../../../assets/icons/clients";
import { ArrowIcon } from "../../../assets/icons/icons";
import { useState } from "react";

function Clients() {
    const [my_swiper, set_my_swiper] = useState({});
    return (
        <div className='clients'>
            <h4 className='clients__title'><span className='clients__title_contrast'>Our</span> Clients</h4>
            <div className="clients__swiper-box">
                <button className="clients__swiper-arrow-btn" type="button"
                    onClick={() => {
                        if (my_swiper) {
                            my_swiper.slidePrev();
                        }
                    }}>
                    <ArrowIcon mainClassName={'clients__swiper-arrow-icon'} />
                </button>
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={0}
                    navigation
                    loop={true}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                    }}
                    mousewheel={{
                        forceToAxis: true,
                    }}
                    onInit={(ev) => {
                        set_my_swiper(ev)
                    }}
                    modules={[Mousewheel, Navigation, Pagination]}
                    preventInteractionOnTransition={true}
                    className={`clients__box_swipper pagination_clients`}
                >
                    {CLIENTS_CARDS.map((item, i) => (
                        <SwiperSlide className='clients__box_slide'>
                            {item.icon({ mainClassName: 'clients__box_slide-icon' })}
                        </SwiperSlide>
                    ))}


                </Swiper>
                <button className="clients__swiper-arrow-btn" type="button"
                    onClick={() => {
                        if (my_swiper) {
                            my_swiper.slideNext();
                        }
                    }}>
                    <ArrowIcon mainClassName={'clients__swiper-arrow-icon clients__swiper-arrow-icon_prew'} />
                </button>
            </div>

        </div>
    );
}

export default Clients