import card from '../../../../assets/images/potrfolio/beyou/beyou.webp'
import './BeYou.css';
import { Link, useLocation } from 'react-router-dom';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import CompTemplate from '../CompTemplate/CompTemplate';

function BeYou({company}) {
    const windowSize = useWindowSize()
    let location = useLocation();
    return (
        <CompTemplate
            company={company}
            className={'beyou-project-card'}
            project_name={'BeYou'}
            project_description={'Korean clothing brand'}
        >
             <Link to={`/${company}`} state={{ backgroundLocation: location }}>
            <div className='beyou-project-card__content'>
              <img className='beyou-project-card__content__img' src={card} alt=''></img>
            </div>
            </Link>
        </CompTemplate>
    );
}

export default BeYou;