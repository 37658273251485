import openreview_baner from '../../assets/images/potrfolio/review/banerBig.webp';
import openreview_baner_middle from '../../assets/images/potrfolio/review/banerMiddle.webp';
import openreview_baner_small from '../../assets/images/potrfolio/review/banerSmall.webp';

import pet4me_baner from '../../assets/images/potrfolio/pet4me/banerBig.webp';
import pet4me_baner_middle from '../../assets/images/potrfolio/pet4me/banerMiddle.webp';
import pet4me_baner_small from '../../assets/images/potrfolio/pet4me/banerSmall.webp';

import zam_baner from '../../assets/images/potrfolio/zam/banerBig.webp';
import zam_baner_middle from '../../assets/images/potrfolio/zam/banerMiddle.webp';
import zam_baner_small from '../../assets/images/potrfolio/zam/banerSmall.webp';

import sputnik_baner from '../../assets/images/potrfolio/sputnik/banerBig.webp';
import sputnik_baner_middle from '../../assets/images/potrfolio/sputnik/banerMiddle.webp';
import sputnik_baner_small from '../../assets/images/potrfolio/sputnik/banerSmall.webp';

import jelly_baner from '../../assets/images/potrfolio/jelly/banerBig.webp';
import jelly_baner_middle from '../../assets/images/potrfolio/jelly/banerMiddle.webp';
import jelly_baner_small from '../../assets/images/potrfolio/jelly/banerSmall.webp';

import higgs_baner from '../../assets/images/potrfolio/higgs/banerBig.webp';
import higgs_baner_middle from '../../assets/images/potrfolio/higgs/banerMiddle.webp';
import higgs_baner_small from '../../assets/images/potrfolio/higgs/banerSmall.webp';

import metamedix_baner from '../../assets/images/potrfolio/metamedix/banerBig.webp';
import metamedix_baner_middle from '../../assets/images/potrfolio/metamedix/banerMiddle.webp';
import metamedix_baner_small from '../../assets/images/potrfolio/metamedix/banerSmall.webp';

import guestme_baner from '../../assets/images/potrfolio/guestme/banerBig.webp';
import guestme_baner_middle from '../../assets/images/potrfolio/guestme/banerMiddle.webp';
import guestme_baner_small from '../../assets/images/potrfolio/guestme/banerSmall.webp';

import cabasuite_baner from '../../assets/images/potrfolio/cabasuite/banerBig.webp';
import cabasuite_baner_middle from '../../assets/images/potrfolio/cabasuite/banerMiddle.webp';
import cabasuite_baner_small from '../../assets/images/potrfolio/cabasuite/banerSmall.webp';

import paraliq_baner from '../../assets/images/potrfolio/paraliq/banerBig.webp';
import paraliq_baner_middle from '../../assets/images/potrfolio/paraliq/banerMiddle.webp';
import paraliq_baner_small from '../../assets/images/potrfolio/paraliq/banerSmall.webp';

import mgc_baner from '../../assets/images/potrfolio/mgc/bigBaner.webp';
import mgc_baner_middle from '../../assets/images/potrfolio/mgc/banerMiddle.webp';
import mgc_baner_small from '../../assets/images/potrfolio/mgc/banerSmall.webp';


import SBA_bg from '../images/potrfolio/SBA/SBA-bg.webp';
import sba_baner from '../images/potrfolio/SBA/sba.webp';

import boomsite_baner from '../images/potrfolio/BoomSite/boombaner.webp';
import boomsite_bg from '../images/potrfolio/BoomSite/boom.webp';

import bigbro_bg from '../images/potrfolio/bigbro/bigbro_card_bg.webp';
import bigbro_baner from '../images/potrfolio/bigbro/bigbro.webp';

import brainspace_bg from '../images/potrfolio/BrainSpace/BrainSpace.webp';
import brainspace_baner from '../images/potrfolio/BrainSpace/brain.webp';

import beyou_bg from '../images/potrfolio/beyou/beyou.webp';
import beyou_baner from '../images/potrfolio/beyou/beyou-3.webp';

import divanchik_bg from '../images/potrfolio/divanchik/divanchik_card_bg.webp';
import divanchik_baner from '../images/potrfolio/divanchik/divanchik-1.webp';

export const PROJECTS_DEVELOPMENT = [
    {
        id: "openreview",
        name: "Open Review",
        text: [
            'App',
        ],
        baner: openreview_baner,
        baner_middle: openreview_baner_middle,
        baner_small: openreview_baner_small,
        field: 'an application for reading and listening to books',
        field_discription: "An application that allows to find a book by scanning it with a camera. A reader also can write a review of the book and read other people's reviews from the library. This increases the popularity of Open Review among book lovers."
    },
    {
        id: "pet4me",
        name: "Pet4Me",
        text: [
            'Website',
        ],
        baner: pet4me_baner,
        baner_middle: pet4me_baner_middle,
        baner_small: pet4me_baner_small,
        field: 'a marketplace with dogs from trusted breeders who run their own blogs on the platform',
        field_discription: 'We created a website for the company that structured all the information and established communication between the seller and the buyer. As a result, our customer’s sales have increased and audience trust has grew.'
    },
    {
        id: "zam",
        name: "ZAM",
        text: [
            'Website',
            'App',
        ],
        baner: zam_baner,
        baner_middle: zam_baner_middle,
        baner_small: zam_baner_small,
        field: 'a platform for monitoring health and taking pills that can broadcast data to the doctor',
        field_discription: 'Both a website and an application were created for the company, which allowed users to choose the most convenient option, and, as a result, increased the popularity of Zam.'
    },
    {
        id: "sputnik",
        name: "Sputnik",
        text: [
            'Website',
            'Logo',
        ],
        baner: sputnik_baner,
        baner_middle: sputnik_baner_middle,
        baner_small: sputnik_baner_small,
        field: 'investing in companies with great ambitions',
        field_discription: 'The company invests in early stage businesses, providing seed and venture capital to support their development and growth objectives.'
    },
    {
        id: "jellybet",
        name: "Jelly Bet Casino",
        text: [
            'Website',
            'Games',
        ],
        baner: jelly_baner,
        baner_middle: jelly_baner_middle,
        baner_small: jelly_baner_small,
        field: 'a web3.0 online casino with games using crypto',
        field_discription: 'We developed a website and games for the company, which is why they have the capabilities to efficiently draw the audience and keep it engaged.'
    },
    {
        id: "higgs",
        name: "Higgs",
        text: [
            'Website',
            'NFT marketplace',
        ],
        baner: higgs_baner,
        baner_middle: higgs_baner_middle,
        baner_small: higgs_baner_small,
        field: 'a marketplace for NFT on the NEAR blockchain',
        field_discription: 'We have created a website for the sale of NFT, which has helped many authors to find their audience. As a result, Higgs is actively developing and increasing its income.'
    },
    {
        id: "metamedix",
        name: "Meta Medix",
        text: [
            'Branding',
        ],
        baner: metamedix_baner,
        baner_middle: metamedix_baner_middle,
        baner_small: metamedix_baner_small,
        field: 'a next-generation pharmaceutical company supplying plant-inspired medication “phytomedicine”',
        field_discription: 'Our team has developed a branding for a client that differs from competitors and thus makes Meta Medix the most memorable among other companies.'
    },
    {
        id: "guestme",
        name: "Guestme",
        text: [
            'App',
        ],
        baner: guestme_baner,
        baner_middle: guestme_baner_middle,
        baner_small: guestme_baner_small,
        field: 'an application for creating parties and checking-in people who want to join',
        field_discription: 'Our team has created an application with a check-in system: a person selects a location on the map, describes the event and indicates the number of people who can join. Guestme is actively gaining popularity among people who want to find friends by their interests.'
    },
    {
        id: "cabasuite",
        name: "Caba Suite",
        text: [
            'CMS',
        ],
        baner: cabasuite_baner,
        baner_middle: cabasuite_baner_middle,
        baner_small: cabasuite_baner_small,
        field: 'an admin panel for Caba Hub projects, which allows to change settings without coding required',
        field_discription: 'This project is our unique development of a special CMS system that has structured and simplified the work on our projects.'
    },
    {
        id: "paraliq",
        name: "Paraliq",
        text: [
            'Games',
        ],
        baner: paraliq_baner,
        baner_middle: paraliq_baner_middle,
        baner_small: paraliq_baner_small,
        field: 'platform launch a brand new Web3 iGaming project, or enhance your existing one',
        field_discription: 'We developed a unique platform where users can multiply their crypto by playing fun games directly from their Web3 wallet without any deposits to third parties.'
    },
    {
        id: "mgc",
        name: "MGC Pharma",
        text: [
            'Website',
        ],
        baner: mgc_baner,
        baner_middle: mgc_baner_middle,
        baner_small: mgc_baner_small,
        field: 'a modern pharmaceutical medical plants company',
        field_discription: 'We have created a website that has revealed the potential and benefits of herbal medicine. The harmonious combination of a big amount of sections allowed to create a full picture of MGC Pharma.'
    },
    {
        id: "sba",
        name: "SBA Club",
        bg: SBA_bg,

        text: [
            'Branding',
            'Brand book',
            'Logo',
        ],
        baner: sba_baner,
        baner_middle: sba_baner,
        baner_small: sba_baner,
        field: 'brazilian Jiu-Jitsu Federation',
        field_discription: 'In this case, we developed branding and a brand book, and also created the SBA company logo. As a result, the client received a holistic image of the brand, whose philosophy is understandable and close to the target audience.'
    },
    {
        id: "boomsite",
        name: "Boom Telecom",
        bg: boomsite_bg,
        text: [
            'Website',
        ],
        baner: boomsite_baner,
        baner_middle: boomsite_baner,
        baner_small: boomsite_baner,
        field: 'modern mobile operator',
        field_discription: "In this part of the case about Boom Telecom, we talk about the company website we created. The site is a full-fledged resource, to which an application has been created in addition. As a result, the company has become maximally focused on its client (young people), having two platforms for interacting with them.",
    },
    {
        id: "bigbro",
        name: "BIG BRO PRO",
        bg: bigbro_bg,
        text: [
            'Website',
            'Rebranding',
        ],
        baner: bigbro_baner,
        baner_middle: bigbro_baner,
        baner_small: bigbro_baner,
        field: 'modern and stylish events in Moscow',
        field_discription: "We have created a website and rebranded the company. Thanks to this, BIG BRO PRO has an updated image that catches the audience.",
    },
    {
        id: "brainspace",
        name: "Brain Space",
        bg: brainspace_bg,
        text: [
            'Website',
            'Branding',
            'Logo',
        ],
        baner: brainspace_baner,
        baner_middle: brainspace_baner,
        baner_small: brainspace_baner,
        field: 'service for the selection of psychologists and psychotherapists, online consultations',
        field_discription: "We have developed branding for Brain Space, as well as created a website and a logo. As a result, they found their own corporate identity, soft and aesthetic, which is so important for this field of activity.",
    },
    {
        id: "beyou",
        name: "BeYou",
        bg: beyou_bg,
        text: [
            'Website',
            'Brand identity',
            'Logo',
            'Promotion',
        ],
        baner: beyou_baner,
        baner_middle: beyou_baner,
        baner_small: beyou_baner,
        field: 'Korean clothing brand',
        field_discription: "We have developed a brand identity and logo, as well as created a website. At the moment, thanks to these stages, BeYou has already begun to develop, however, in order to increase efficiency, the client turned to us for marketing promotion, which will be carried out in the near future.",
    },
    {
        id: "divanchik",
        name: "Divanchik",
        bg: divanchik_bg,
        text: [
            'Website',
            'Rebranding',
            'Promotion',
        ],
        baner: divanchik_baner,
        baner_middle: divanchik_baner,
        baner_small: divanchik_baner,
        field: 'a furniture market popular in Siberia',
        field_discription: "For this company, we rebranded the site and set up marketing promotion, as a result of which the Sofa catalog became much more convenient, and our client's audience quickly and easily learns about it."
    },
];

export const getProject = (tid) => PROJECTS_DEVELOPMENT.find(({ id }) => id === tid);

export const HedaerLogoDesktop = () => {
    return (
        <svg
            width={130}
            height={44}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.88 26.56v-4.44h6.52c.853 0 1.627-.173 2.32-.52a4.004 4.004 0 001.64-1.48c.4-.667.6-1.467.6-2.4 0-.907-.2-1.693-.6-2.36a3.921 3.921 0 00-1.64-1.52c-.693-.347-1.467-.52-2.32-.52H6.88V8.88h7.08c1.787 0 3.387.36 4.8 1.08 1.44.72 2.573 1.747 3.4 3.08.827 1.307 1.24 2.867 1.24 4.68 0 1.813-.413 3.387-1.24 4.72-.827 1.307-1.96 2.32-3.4 3.04-1.413.72-3.013 1.08-4.8 1.08H6.88zM2.88 37V8.88h5.44V37H2.88zm32.27.4c-1.947 0-3.707-.44-5.28-1.32a10.573 10.573 0 01-3.72-3.64c-.907-1.547-1.36-3.267-1.36-5.16 0-1.893.453-3.587 1.36-5.08a9.846 9.846 0 013.72-3.6c1.573-.907 3.32-1.36 5.24-1.36 1.973 0 3.733.453 5.28 1.36a9.846 9.846 0 013.72 3.6c.907 1.493 1.36 3.187 1.36 5.08 0 1.893-.453 3.613-1.36 5.16-.907 1.52-2.147 2.733-3.72 3.64-1.547.88-3.293 1.32-5.24 1.32zm-.04-4.88c.987 0 1.853-.213 2.6-.64a4.494 4.494 0 001.76-1.84c.427-.8.64-1.707.64-2.72 0-1.013-.213-1.907-.64-2.68a4.572 4.572 0 00-1.76-1.8c-.747-.453-1.613-.68-2.6-.68-.96 0-1.813.227-2.56.68a4.572 4.572 0 00-1.76 1.8c-.427.773-.64 1.667-.64 2.68 0 1.013.213 1.92.64 2.72a4.861 4.861 0 001.76 1.84c.747.427 1.6.64 2.56.64zM48.843 37V8.08h5.24V37h-5.24zm8.523-3l10.24-13.32h6.32L63.686 34h-6.32zm0 3v-3l4.16-1.56h12.16V37h-16.32zm.72-14.76v-4.56h15.84v3l-4.2 1.56h-11.64zM85.088 37.4c-1.76 0-3.347-.44-4.76-1.32-1.414-.88-2.52-2.08-3.32-3.6-.8-1.52-1.2-3.227-1.2-5.12 0-1.92.4-3.64 1.2-5.16.8-1.52 1.906-2.72 3.32-3.6 1.413-.88 3-1.32 4.76-1.32 1.386 0 2.626.28 3.72.84a6.538 6.538 0 012.6 2.36c.666.987 1.026 2.107 1.08 3.36v6.96c-.054 1.28-.414 2.413-1.08 3.4-.64.987-1.507 1.773-2.6 2.36-1.094.56-2.334.84-3.72.84zm.96-4.84c1.466 0 2.653-.48 3.56-1.44.906-.987 1.36-2.253 1.36-3.8 0-1.013-.214-1.907-.64-2.68-.4-.8-.974-1.413-1.72-1.84-.72-.453-1.574-.68-2.56-.68-.96 0-1.814.227-2.56.68-.72.427-1.294 1.04-1.72 1.84-.4.773-.6 1.667-.6 2.68 0 1.04.2 1.96.6 2.76.426.773 1 1.387 1.72 1.84.746.427 1.6.64 2.56.64zm4.64 4.44v-5.2l.84-4.72-.84-4.64v-4.76h5.2V37h-5.2z"
                fill="#fff"
            />
            <path fill="#FF8585" d="M103 11h6v6h-6z" />
            <path fill="#FFB84F" d="M113.062 11h6v6h-6z" />
            <path fill="#7AE448" d="M123.125 11h6v6h-6z" />
        </svg>
    );
}

export const HeaderLogoTablet = () => {
    return (
        <svg
            width={103}
            height={34}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.16 20.17v-3.33h4.89c.64 0 1.22-.13 1.74-.39s.93-.63 1.23-1.11c.3-.5.45-1.1.45-1.8 0-.68-.15-1.27-.45-1.77-.3-.5-.71-.88-1.23-1.14-.52-.26-1.1-.39-1.74-.39H5.16V6.91h5.31c1.34 0 2.54.27 3.6.81 1.08.54 1.93 1.31 2.55 2.31.62.98.93 2.15.93 3.51 0 1.36-.31 2.54-.93 3.54-.62.98-1.47 1.74-2.55 2.28-1.06.54-2.26.81-3.6.81H5.16zm-3 7.83V6.91h4.08V28H2.16zm24.203.3c-1.46 0-2.78-.33-3.96-.99a7.93 7.93 0 01-2.79-2.73c-.68-1.16-1.02-2.45-1.02-3.87s.34-2.69 1.02-3.81a7.384 7.384 0 012.79-2.7c1.18-.68 2.49-1.02 3.93-1.02 1.48 0 2.8.34 3.96 1.02 1.18.66 2.11 1.56 2.79 2.7.68 1.12 1.02 2.39 1.02 3.81s-.34 2.71-1.02 3.87a7.613 7.613 0 01-2.79 2.73c-1.16.66-2.47.99-3.93.99zm-.03-3.66c.74 0 1.39-.16 1.95-.48.58-.34 1.02-.8 1.32-1.38.32-.6.48-1.28.48-2.04s-.16-1.43-.48-2.01c-.32-.58-.76-1.03-1.32-1.35-.56-.34-1.21-.51-1.95-.51-.72 0-1.36.17-1.92.51-.56.32-1 .77-1.32 1.35-.32.58-.48 1.25-.48 2.01 0 .76.16 1.44.48 2.04.32.58.76 1.04 1.32 1.38.56.32 1.2.48 1.92.48zm10.3 3.36V6.31h3.93V28h-3.93zm6.392-2.25l7.68-9.99h4.74l-7.68 9.99h-4.74zm0 2.25v-2.25l3.12-1.17h9.12V28h-12.24zm.54-11.07v-3.42h11.88v2.25l-3.15 1.17h-8.73zm20.25 11.37c-1.32 0-2.51-.33-3.57-.99a6.9 6.9 0 01-2.49-2.7c-.6-1.14-.9-2.42-.9-3.84 0-1.44.3-2.73.9-3.87a6.9 6.9 0 012.49-2.7c1.06-.66 2.25-.99 3.57-.99 1.04 0 1.97.21 2.79.63.82.42 1.47 1.01 1.95 1.77.5.74.77 1.58.81 2.52v5.22c-.04.96-.31 1.81-.81 2.55-.48.74-1.13 1.33-1.95 1.77-.82.42-1.75.63-2.79.63zm.72-3.63c1.1 0 1.99-.36 2.67-1.08.68-.74 1.02-1.69 1.02-2.85 0-.76-.16-1.43-.48-2.01-.3-.6-.73-1.06-1.29-1.38-.54-.34-1.18-.51-1.92-.51-.72 0-1.36.17-1.92.51-.54.32-.97.78-1.29 1.38-.3.58-.45 1.25-.45 2.01 0 .78.15 1.47.45 2.07.32.58.75 1.04 1.29 1.38.56.32 1.2.48 1.92.48zm3.48 3.33v-3.9l.63-3.54-.63-3.48v-3.57h3.9V28h-3.9z"
                fill="#fff"
            />
            <path fill="#FF8585" d="M76 6.75h6v6h-6z" />
            <path fill="#FFB84F" d="M86.063 6.75h6v6h-6z" />
            <path fill="#7AE448" d="M96.125 6.75h6v6h-6z" />
        </svg>
    );
}

export const HeaderLogoMob = () => {
    return (
        <svg
            width={68}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.44 13.78v-2.22H6.7c.427 0 .813-.087 1.16-.26.347-.173.62-.42.82-.74.2-.333.3-.733.3-1.2 0-.453-.1-.847-.3-1.18a1.96 1.96 0 00-.82-.76 2.557 2.557 0 00-1.16-.26H3.44V4.94h3.54c.893 0 1.693.18 2.4.54a4.13 4.13 0 011.7 1.54c.413.653.62 1.433.62 2.34 0 .907-.207 1.693-.62 2.36a4.176 4.176 0 01-1.7 1.52c-.707.36-1.507.54-2.4.54H3.44zm-2 5.22V4.94h2.72V19H1.44zm16.135.2c-.973 0-1.853-.22-2.64-.66a5.287 5.287 0 01-1.86-1.82c-.453-.773-.68-1.633-.68-2.58 0-.947.227-1.793.68-2.54a4.922 4.922 0 011.86-1.8c.787-.453 1.66-.68 2.62-.68.987 0 1.867.227 2.64.68a4.922 4.922 0 011.86 1.8c.453.747.68 1.593.68 2.54 0 .947-.227 1.807-.68 2.58a5.074 5.074 0 01-1.86 1.82c-.773.44-1.647.66-2.62.66zm-.02-2.44c.493 0 .927-.107 1.3-.32.387-.227.68-.533.88-.92.213-.4.32-.853.32-1.36 0-.507-.107-.953-.32-1.34a2.285 2.285 0 00-.88-.9c-.373-.227-.807-.34-1.3-.34-.48 0-.907.113-1.28.34a2.285 2.285 0 00-.88.9c-.213.387-.32.833-.32 1.34 0 .507.107.96.32 1.36.213.387.507.693.88.92.373.213.8.32 1.28.32zM24.422 19V4.54h2.62V19h-2.62zm4.261-1.5l5.12-6.66h3.16l-5.12 6.66h-3.16zm0 1.5v-1.5l2.08-.78h6.08V19h-8.16zm.36-7.38V9.34h7.92v1.5l-2.1.78h-5.82zm13.5 7.58c-.88 0-1.673-.22-2.38-.66a4.6 4.6 0 01-1.66-1.8c-.4-.76-.6-1.613-.6-2.56 0-.96.2-1.82.6-2.58a4.6 4.6 0 011.66-1.8c.707-.44 1.5-.66 2.38-.66.694 0 1.314.14 1.86.42.547.28.98.673 1.3 1.18.334.493.514 1.053.54 1.68v3.48a3.212 3.212 0 01-.54 1.7c-.32.493-.753.887-1.3 1.18-.546.28-1.166.42-1.86.42zm.48-2.42c.734 0 1.327-.24 1.78-.72.454-.493.68-1.127.68-1.9 0-.507-.106-.953-.32-1.34-.2-.4-.486-.707-.86-.92-.36-.227-.786-.34-1.28-.34-.48 0-.906.113-1.28.34-.36.213-.646.52-.86.92-.2.387-.3.833-.3 1.34 0 .52.1.98.3 1.38.214.387.5.693.86.92.374.213.8.32 1.28.32zm2.32 2.22v-2.6l.42-2.36-.42-2.32V9.34h2.6V19h-2.6z"
                fill="#fff"
            />
            <path fill="#FF8585" d="M52 5h4v4h-4z" />
            <path fill="#FFB84F" d="M58 5h4v4h-4z" />
            <path fill="#7AE448" d="M64 5h4v4h-4z" />
        </svg>
    );
}

export const PhoneIcon = () => {
    return (
        <svg
            width={32}
            height={32}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.015 22.672l-1.692-1.691a2.161 2.161 0 00-3.057 0l-1.33 1.33a1.024 1.024 0 01-1.13.227c-1.925-.843-3.828-2.122-5.525-3.819-1.69-1.69-2.964-3.584-3.809-5.502a1.057 1.057 0 01.234-1.17l1.19-1.19c.968-.968.968-2.336.123-3.18L9.328 5.986a2.883 2.883 0 00-4.077 0l-.94.938c-1.068 1.068-1.513 2.61-1.225 4.137.712 3.767 2.9 7.892 6.43 11.422 3.531 3.53 7.656 5.719 11.423 6.431 1.528.288 3.069-.157 4.137-1.225l.939-.939a2.884 2.884 0 000-4.078zM17.415 8.805c1.487-.02 2.98.53 4.115 1.665M25.607 6.394A11.55 11.55 0 0017.415 3M23.195 14.585a5.686 5.686 0 00-1.665-4.115M25.607 6.394A11.55 11.55 0 0129 14.584"
                stroke="#fff"
                strokeWidth={1.6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export const PhoneIconTablet = () => {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.51 17.004l-1.267-1.268a1.621 1.621 0 00-2.294 0l-.996.997a.768.768 0 01-.848.17c-1.445-.632-2.872-1.591-4.144-2.864-1.268-1.267-2.223-2.688-2.857-4.127a.793.793 0 01.175-.877l.893-.893c.726-.725.726-1.751.092-2.385L6.996 4.49a2.162 2.162 0 00-3.058 0l-.705.704c-.8.801-1.135 1.957-.919 3.103.534 2.825 2.176 5.918 4.824 8.566 2.647 2.648 5.74 4.29 8.566 4.824 1.146.216 2.302-.118 3.103-.92l.704-.703a2.163 2.163 0 000-3.06zM13.062 6.604a4.264 4.264 0 013.085 1.249M19.205 4.795a8.662 8.662 0 00-6.143-2.545M17.396 10.938a4.264 4.264 0 00-1.249-3.085M19.205 4.795a8.662 8.662 0 012.545 6.143"
                stroke="#fff"
                strokeWidth={1.6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export const EmailIcon = () => {
    return (
        <svg
            width={32}
            height={32}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23.222 6H8.778C5.587 6 3 8.388 3 11.333v9.334C3 23.612 5.587 26 8.778 26h14.444C26.413 26 29 23.612 29 20.667v-9.334C29 8.388 26.413 6 23.222 6z"
                stroke="#fff"
                strokeWidth={1.6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.778 11.904l6.073 2.49a3.047 3.047 0 002.301.003l6.07-2.468"
                stroke="#fff"
                strokeWidth={1.6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export const EmailIconTablet = () => {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.417 4.5H6.583c-2.393 0-4.333 1.79-4.333 4v7c0 2.21 1.94 4 4.333 4h10.834c2.393 0 4.333-1.79 4.333-4v-7c0-2.21-1.94-4-4.333-4z"
                stroke="#fff"
                strokeWidth={1.6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.583 8.928l4.556 1.867a2.284 2.284 0 001.725.002l4.553-1.85"
                stroke="#fff"
                strokeWidth={1.6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export const HeaderMenuMobOpenBtn = () => {
    return (
        <svg
            width={23}
            height={22}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.583 4H21l-1.583 2H2l1.583-2zM3.429 10H12l-1.429 2H2l1.429-2zM3.583 16H21l-1.583 2H2l1.583-2z"
                fill="#fff"
            />
        </svg>
    );
}

export const HeaderMenuMobCloseBtn = () => {
    return (
        <svg
            width={23}
            height={22}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.12 17.316L17.435 5l.295 2.534L5.414 19.849l-.294-2.534z"
                fill="#fff"
            />
            <path
                d="M17.73 17.316L5.414 5 5.12 7.534l12.315 12.315.295-2.534z"
                fill="#fff"
            />
        </svg>
    );
}

export const PopupHeaderEmailIcon = () => {
    return (
        <svg
            width={44}
            height={44}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M30.462 10.154H13.538c-3.738 0-6.769 2.828-6.769 6.318v11.056c0 3.49 3.03 6.318 6.77 6.318h16.923c3.738 0 6.769-2.829 6.769-6.318V16.472c0-3.49-3.03-6.318-6.77-6.318z"
                stroke="#fff"
                strokeWidth={1.4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.539 17.148l7.115 2.949a3.534 3.534 0 002.696.004l7.111-2.924"
                stroke="#fff"
                strokeWidth={1.4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export const PopupHeaderPhoneIcon = () => {
    return (
        <svg
            width={44}
            height={44}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M32.43 28.948l-1.762-1.76a2.25 2.25 0 00-3.184 0L26.1 28.571c-.31.309-.778.412-1.177.237-2.006-.879-3.988-2.21-5.755-3.977-1.76-1.76-3.087-3.733-3.966-5.73a1.1 1.1 0 01.243-1.218l1.24-1.24c1.007-1.008 1.007-2.432.128-3.312l-1.761-1.761a3.002 3.002 0 00-4.246 0l-.979.977c-1.112 1.113-1.576 2.717-1.276 4.309.742 3.923 3.02 8.218 6.697 11.895 3.677 3.676 7.972 5.955 11.895 6.697 1.592.3 3.197-.164 4.309-1.276l.977-.977a3.004 3.004 0 000-4.248zM23.474 14.507a5.921 5.921 0 014.285 1.734M32.005 11.996a12.028 12.028 0 00-8.53-3.534M29.492 20.526a5.921 5.921 0 00-1.733-4.285M32.005 11.996a12.028 12.028 0 013.533 8.53"
                stroke="#fff"
                strokeWidth={1.4}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export const DiagramIcon = () => {
    return (
        <svg
            width={240}
            height={240}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#prefix__clip0_1146_1810)">
                <path
                    d="M150.906 90.094c16.792 16.793 16.792 44.019 0 60.812-16.793 16.792-44.019 16.792-60.812 0-16.792-16.793-16.792-44.019 0-60.812 16.793-16.792 44.019-16.792 60.812 0z"
                    fill="url(#prefix__paint0_linear_1146_1810)"
                />
                <path
                    d="M120.5 106.055V120.5M130.709 110.29c5.639 5.639 5.639 14.783 0 20.422-5.639 5.639-14.782 5.639-20.421 0-5.64-5.639-5.64-14.783 0-20.422"
                    stroke="#67E4FF"
                    strokeWidth={4}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M120 50.788c-38.225 0-69.212 30.987-69.212 69.212 0 38.225 30.987 69.212 69.212 69.212 38.225 0 69.212-30.987 69.212-69.212 0-38.225-30.987-69.212-69.212-69.212zM0 120C0 53.726 53.726 0 120 0s120 53.726 120 120-53.726 120-120 120S0 186.274 0 120z"
                    fill="url(#prefix__paint1_linear_1146_1810)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M189.212 120c0-38.225-30.987-69.212-69.212-69.212V0c66.274 0 120 53.726 120 120 0 9.529-1.116 18.83-3.235 27.767l-49.418-11.717a69.497 69.497 0 001.865-16.05z"
                    fill="#2D42FD"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M182.111 89.416C170.787 66.478 147.202 50.788 120 50.788V0c47.265 0 88.1 27.33 107.652 66.933l-45.541 22.483z"
                    fill="#2D8DFD"
                />
            </g>
            <defs>
                <linearGradient
                    id="prefix__paint0_linear_1146_1810"
                    x1={120.5}
                    y1={-103}
                    x2={120.5}
                    y2={206.5}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5400DE" />
                    <stop offset={1} stopColor="#C5A2FF" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="prefix__paint1_linear_1146_1810"
                    x1={120}
                    y1={183}
                    x2={120}
                    y2={-22}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5321BE" />
                    <stop offset={1} stopColor="#1A205E" stopOpacity={0.38} />
                </linearGradient>
                <clipPath id="prefix__clip0_1146_1810">
                    <path fill="#fff" d="M0 0h240v240H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}

export const SuiteRhombsIcon = () => {
    return (
        <svg
            width={200}
            height={215}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M123.563.893l73.808 92.866a12.13 12.13 0 012.629 7.546v110.811c0 1.903-2.381 2.744-3.562 1.258l-73.807-92.867a12.12 12.12 0 01-2.63-7.545V2.15c0-1.903 2.381-2.743 3.562-1.257z"
                fill="#7AE448"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.567.893l73.808 92.866a12.13 12.13 0 012.629 7.546v110.811c0 1.903-2.38 2.744-3.561 1.258l-73.808-92.867a12.125 12.125 0 01-2.63-7.545V2.15c0-1.903 2.38-2.743 3.562-1.257z"
                fill="#FFB84F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.562.893L77.37 93.759a12.13 12.13 0 012.629 7.546v110.811c0 1.903-2.38 2.744-3.562 1.258L2.63 120.507A12.125 12.125 0 010 112.962V2.15C0 .247 2.38-.593 3.562.893z"
                fill="#FF8585"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M120 71.903l17.37 21.855a12.13 12.13 0 012.63 7.546v41.058l-17.37-21.855a12.126 12.126 0 01-2.63-7.546V71.903z"
                fill="#B1FF4F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M60.005 71.903l17.37 21.855a12.129 12.129 0 012.63 7.546v41.058l-17.37-21.855a12.13 12.13 0 01-2.63-7.546V71.903z"
                fill="#FFD585"
            />
        </svg>
    );
}

export const NumbersRhombIcon = () => {
    return (
        <svg
            width={1393}
            height={2691}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M696.5 0L0 1345.5 696.5 2691 1393 1345.5 696.5 0z"
                fill="#1F2664"
            />
        </svg>
    );
}

export const CrossFeedbackIcon = () => {
    return (
        <svg
            width={24}
            height={24}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3 21L21 3M21 21L3 3"
                stroke="#000536"
                strokeWidth={2}
                strokeLinecap="round"
            />
        </svg>
    );
}

export const SuccessMonitorIcon = () => {
    return (
        <svg
            width={278}
            height={328}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx={142} cy={166} r={124} fill="#C8C7FF" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.735 226.5C63.522 203.014 101.125 189 142 189c40.876 0 78.478 14.014 108.265 37.5C229.047 264.389 188.514 290 142 290s-87.046-25.611-108.265-63.5z"
                fill="#AFADFF"
            />
            <g filter="url(#prefix__filter0_d_794_7729)">
                <g clipPath="url(#prefix__clip0_794_7729)">
                    <rect x={62} y={41} width={160} height={110} rx={16} fill="#8A94F0" />
                    <path d="M34 157h216L142 76 34 157z" fill="#818BEB" />
                    <path d="M62 41h160l-80 60-80-60z" fill="#A4ADFF" />
                    <rect x={119} y={127} width={10} height={10} rx={2} fill="#FF8585" />
                    <rect x={137} y={127} width={10} height={10} rx={2} fill="#FFB84F" />
                    <rect x={155} y={127} width={10} height={10} rx={2} fill="#7AE448" />
                </g>
            </g>
            <path
                d="M142 166v79M126 166v39M126 215v10M172 196v7M157 203v17M157 166v27M172 184v-9.391c0-5.523 4.477-10 10-10h36c9.941 0 18-8.059 18-18V45c0-9.941-8.059-18-18-18H66c-9.941 0-18 8.059-18 18v101.609c0 9.941 8.059 18 18 18h36c5.523 0 10 4.477 10 10V193"
                stroke="#593FF8"
                strokeWidth={3}
                strokeLinecap="round"
            />
            <path
                d="M18 20l5.091 12.909L36 38l-12.909 5.091L18 56l-5.091-12.909L0 38l12.909-5.091L18 20z"
                fill="#569AFF"
            />
            <path
                d="M61.5 245l3.818 9.682L75 258.5l-9.682 3.818L61.5 272l-3.818-9.682L48 258.5l9.682-3.818L61.5 245z"
                fill="#7A77F4"
            />
            <path
                d="M141.5 307l2.97 7.53 7.53 2.97-7.53 2.97-2.97 7.53-2.97-7.53-7.53-2.97 7.53-2.97 2.97-7.53z"
                fill="#3935FF"
            />
            <path
                d="M265 157l3.677 9.323L278 170l-9.323 3.677L265 183l-3.677-9.323L252 170l9.323-3.677L265 157z"
                fill="#567BFF"
            />
            <circle cx={250.5} cy={59.5} r={3.5} fill="#53BDF9" />
            <circle cx={199.5} cy={228.5} r={3.5} fill="#8991E9" />
            <circle cx={40} cy={184} r={5} fill="#8991E9" />
            <circle cx={142} cy={5} r={5} fill="#5867FF" />
            <defs>
                <clipPath id="prefix__clip0_794_7729">
                    <rect x={62} y={41} width={160} height={110} rx={16} fill="#fff" />
                </clipPath>
                <filter
                    id="prefix__filter0_d_794_7729"
                    x={42}
                    y={29}
                    width={200}
                    height={150}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy={8} />
                    <feGaussianBlur stdDeviation={10} />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix values="0 0 0 0 0.357049 0 0 0 0 0.392878 0 0 0 0 0.758333 0 0 0 0.4 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_794_7729"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_794_7729"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}