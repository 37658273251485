import './Process.css';

function Process() {
    return (
        <div className='process'>
            <h4 className='process__title'><span className='process__title_contrast'>Explore</span> Our Process</h4>
            <div className='process__items'>
                <div className='process__item'>
                    <span className='process__item-numeral'>01</span>
                    <h5 className='process__item-title'>Strategy.</h5>
                    <p className='process__item-text'>Before we take a project into development, we dive into the client’s goals and objectives. Together with the client we work out a strategy for the development of the future product. After aggregating the information from the client, we decide how best and quickly to implement their needs</p>
                </div>
                <div className='process__item'>
                    <span className='process__item-numeral'>02</span>
                    <h5 className='process__item-title'>Design.</h5>
                    <p className='process__item-text'>Design is a necessary part of almost every project, either it is user research or designing an interface from scratch. We like to design interfaces that effectively solve user problems, and increase confidence in the client’s business</p>
                </div>
                <div className='process__item'>
                    <span className='process__item-numeral'>03</span>
                    <h5 className='process__item-title'>Development.</h5>
                    <p className='process__item-text'>We work according to the client’s procedures from the get-go and assemble a team according to the project-specific tasks. We regularly provide feedback and collate reports as we are working on the project. Working in short sprints allows you to provide measurable results on a regular basis. Each sprint ends with a demonstration of results.</p>
                </div>
            </div>
        </div>
    );
}

export default Process