import { useRef, useState } from 'react';
import CardTemplate from '../CardTemplate/CardTemplate';
import './PhoneTemplate.css';
import { CameraAppleIconIcon } from '../../../../assets/icons/icons';

function PhoneTemplate({
    children,
    className,
    project_name,
    project_description,
    company
}) {

    const [hovered, setHovered] = useState(false);
    const hoverTimeout = useRef(null);

    const handleMouseEnter = () => {
        hoverTimeout.current = setTimeout(() => {
            setHovered(true);
        }, 2500); // 5 seconds
    };

    const handleMouseLeave = () => {
        if (hovered) return
        clearTimeout(hoverTimeout.current);
        setHovered(false);
    };


    return (
        <CardTemplate
        company={company}
            className={className}
            project_name={project_name}
            project_description={project_description}
        >
            <div className={`phone-template`}>

                <div className='phone-template__content'>
                    {children}
                </div>
                <div className='phone-template__camera'>
                    <div
                        className='phone-template__camera-element'
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    ></div>
                    <div className='phone-template__camera-speaker'></div>
                </div>


                <div className={`phone-template__hover-notification ${hovered ? 'phone-template__hover-notification_visible' : ''}`} onClick={() => { setHovered(false) }}>
                    <div className='phone-template__hover-notification-heading'>
                        <div className='phone-template__hover-notification-app'>
                            <CameraAppleIconIcon mainClassName={'phone-template__hover-notification-app-icon'} />
                        </div>
                        <p className='phone-template__hover-notification-app-name'>Camera</p>
                        <p className='phone-template__hover-notification-app-time'>1m ago</p>
                    </div>
                    <p className='phone-template__hover-notification-text'>Please avoid closing the camera with your cursor to ensure uninterrupted photo and video capture. Thank you!</p>
                </div>

            </div>
        </CardTemplate>

    );
}

export default PhoneTemplate