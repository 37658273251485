import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
import './SolutionPage.css';
import 'swiper/css';
import bg from '../../../assets/images/wl/solutions/bg.webp';
import shape from '../../../assets/images/wl/solutions/bg-shape.webp';
import { WL_CARDS_DATA } from '../../../assets/utils/wlConstants';
import WhiteLabelCard from '../Card/wlCard';
import { SwiperArrow } from '../../../assets/icons/whiteLabel';

function SolutionPage() {
  const { solution } = useParams();
  const [data, setData] = useState(undefined);
  const [cards, setCards] = useState([]);
  const [swiper, setSwiper] = useState({});
  const [isControlDisabled, setIsControlDisabled] = useState({
    prev: true,
    next: true,
  });

  useEffect(() => {
    if (!solution) return;

    const card = WL_CARDS_DATA.find((card) => card.translitName === solution);
    const filteredCards = WL_CARDS_DATA.filter(
      (card) => card.translitName !== solution
    );
    setData(card);
    setCards(filteredCards);
  }, [solution]);

  function handleSwiperInit(swiper) {
    const progress = swiper.progress;
    setSwiper(swiper);
    setIsControlDisabled({ prev: progress === 0, next: progress === 1 });
  }

  function handleProgress(swiper, progress) {
    setIsControlDisabled({ prev: progress === 0, next: progress === 1 });
  }

  return (
    <section className="solution">
      <div className="solution__heading">
        <div className="solution__title-block">
          <h2
            className="solution__title"
            dangerouslySetInnerHTML={{ __html: data?.heading }}
          />
          <p
            className="solution__subtitle"
            dangerouslySetInnerHTML={{ __html: data?.subtitle }}
          />
        </div>
        <img className="solution__heading-icon" src={data?.icon} alt="" />
        <img className="solution__heading-bg" src={bg} alt="" />
      </div>

      <div className="solution__container">
        <div className="solution__content">
          <img className={`solution__img solution__img_type_${data?.translitName}`} src={data?.img} alt="" />
          <div className='solution__info'>
            <p className="solution__text"
              dangerouslySetInnerHTML={{ __html: data?.text }}
            ></p>
            {data?.case_link ?
              <Link className='solution__case-link' to={`/cases/${data.case_link}`}>View Case →</Link>
              : null}
          </div>

        </div>
        <img className="solution__content-bg" src={shape} alt="" />
      </div>

      <div className="solution__cards">
        <button
          className={`solution__swiper-control solution__swiper-control_type_back ${isControlDisabled.prev ? 'solution__swiper-control_disabled' : ''
            }`}
          type="button"
          onClick={() => swiper.slidePrev()}
        >
          <SwiperArrow
            mainClassName="solution__swiper-control-icon"
            fillClassName="solution__swiper-control-icon-fill"
          />
        </button>
        <Swiper
          className="solution__swiper"
          slidesPerView={1.15}
          spaceBetween={8}
          modules={[Mousewheel]}
          preventInteractionOnTransition={true}
          mousewheel={{ forceToAxis: true }}
          onInit={(ev) => handleSwiperInit(ev)}
          onProgress={(ev, progress) => handleProgress(ev, progress)}
          breakpoints={{
            950: {
              slidesPerView: 3,
            },
            800: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
          }}
        >
          {cards.map((card) => (
            <SwiperSlide className="solution__slide" key={card.id}>
              <WhiteLabelCard {...{ card }} isSwiper />
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          className={`solution__swiper-control solution__swiper-control_type_next ${isControlDisabled.next ? 'solution__swiper-control_disabled' : ''
            }`}
          type="button"
          onClick={() => swiper.slideNext()}
        >
          <SwiperArrow
            mainClassName="solution__swiper-control-icon solution__swiper-control-icon_type_next"
            fillClassName="solution__swiper-control-icon-fill"
          />
        </button>
      </div>
    </section>
  );
}

export default SolutionPage;
