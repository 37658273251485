import card from '../../../../assets/images/potrfolio/BoomSite/boombaner.webp'
import './BoomSite.css';
import { Link, useLocation } from 'react-router-dom';
import useWindowSize from '../../../../assets/hooks/useWindowSize';

import CardTemplate from '../CardTemplate/CardTemplate';

function BoomSite({company}) {
    const windowSize = useWindowSize()
    let location = useLocation();
    return (
        <CardTemplate
            company={company}
            className={'boomsite-project-card'}
            project_name={'Boom Telecom'}
            project_description={'Mobile operator'}
        >
             <Link to={`/${company}`} state={{ backgroundLocation: location }}>
            <div className='boomsite-project-card__content'>
              <img className='boomsite-project-card__content__img' src={card} alt=''></img>
            </div>
            </Link>
        </CardTemplate>
    );
}

export default BoomSite;