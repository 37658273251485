import { useState } from "react";

export function NumbersWebsitesIcon({ mainClassName }) {
    const [isClicked, setClicked] = useState(false)
    return (
        <svg
            width={196}
            height={151}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 196 151"
        >
            {!isClicked ?
                <path
                    d="M62 28.2c0-6.72 0-10.08 1.308-12.648a12 12 0 015.244-5.244C71.12 9 74.48 9 81.2 9H196v142H62V28.2z"
                    fill="#6300C8"
                />
                : null}
            {!isClicked ? <circle cx={81.5} cy={24.5} r={3.5} fill="#8AFFF1" onClick={() => { setClicked(true) }} /> : null}
            {!isClicked ? <circle cx={96.5} cy={24.5} r={3.5} fill="#4B0097" /> : null}
            {!isClicked ? <circle cx={111.5} cy={24.5} r={3.5} fill="#4B0097" /> : null}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M126.698 77.014l36 14.4-15.3 6.3-6.3 15.3-14.4-36z"
                fill="#390073"
            />
        </svg>
    );
}

export function NumbersAppIcon({ mainClassName }) {
    return (
        <svg
            width={197}
            height={152}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 197 152"
        >
            <g clipPath="url(#prefix__clip0_450_1254)">
                <path
                    d="M35.02 29.219c0-11.046 8.954-20 20-20h70c11.046 0 20 8.954 20 20v122h-110v-122z"
                    fill="#7C00FA"
                />
                <path
                    opacity={0.6}
                    d="M97.02 168.219c-11.046 0-20-8.955-20-20v-70c0-11.046 8.954-20 20-20h102c11.046 0 20 8.954 20 20v70c0 11.045-8.954 20-20 20h-102z"
                    fill="#4B0097"
                />
                <circle cx={90.52} cy={24.719} r={3.5} fill="#8AFFF1" />
                <rect
                    x={145.02}
                    y={68.219}
                    width={23}
                    height={4}
                    rx={2}
                    fill="#390073"
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0_450_1254">
                    <path
                        fill="#fff"
                        transform="translate(.698 .014)"
                        d="M0 0h196v151H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}


export function NumbersBrandingIcon({ mainClassName }) {
    return (
        <svg
            width={197}
            height={152}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 197 152"
        >
            <g clipPath="url(#prefix__clip0_450_1256)">
                <path
                    opacity={0.6}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M119.541 23.561a6 6 0 00-8.485 0L47.541 87.076a6 6 0 000 8.486l63.515 63.514a6 6 0 008.485 0l63.515-63.514a6 6 0 000-8.486l-63.515-63.514z"
                    fill="#410082"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M119.541 11.562a6 6 0 00-8.485 0L60.541 62.076a6 6 0 000 8.485l50.515 50.515a6 6 0 008.485 0l50.515-50.514a6 6 0 000-8.486l-50.515-50.514z"
                    fill="#5F00BF"
                />
                <path
                    d="M115.298 55.319l2.645 8.355 8.355 2.645-8.355 2.644-2.645 8.356-2.644-8.356-8.356-2.644 8.356-2.645 2.644-8.355z"
                    fill="#8AFFF1"
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0_450_1256">
                    <path
                        fill="#fff"
                        transform="translate(.698 .014)"
                        d="M0 0h196v151H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}


export function NumbersCryptoIcon({ mainClassName }) {
    return (
        <svg
            width={197}
            height={152}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={mainClassName}
            viewBox="0 0 197 152"
        >
            <g clipPath="url(#prefix__clip0_450_1257)">
                <circle cx={172.73} cy={66.929} r={53} fill="#310063" />
                <circle opacity={0.6} cx={101.73} cy={66.929} r={53} fill="#6300C8" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M101.73 55.93l-8 11 8 11 8-11-8-11z"
                    fill="#8AFFF1"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M137.23 106.284c10.746-9.7 17.5-23.739 17.5-39.355 0-15.616-6.754-29.655-17.5-39.355-10.746 9.7-17.5 23.739-17.5 39.355 0 15.616 6.754 29.655 17.5 39.355z"
                    fill="#5F00BF"
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0_450_1257">
                    <path
                        fill="#fff"
                        transform="translate(.698 .014)"
                        d="M0 0h196v151H0z"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}