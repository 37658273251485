import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className='footer'>
      <p className='footer__copyright'>© All Right Reserved&nbsp;&mdash; Caba Hub 2024</p>
      <Link className='footer__doc-link' to={'/docs/privacy-policy'}>Privacy Policy</Link>
    </footer>
  );
}

export default Footer