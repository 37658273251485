import screen1 from '../../../assets/images/potrfolio/mgc/screen1.webp';
import screen2 from '../../../assets/images/potrfolio/mgc/screen2.webp';
import screen3 from '../../../assets/images/potrfolio/mgc/screen3.webp';
import screen4 from '../../../assets/images/potrfolio/mgc/screen4.webp';
import screen5 from '../../../assets/images/potrfolio/mgc/screen5.webp';
import screen6 from '../../../assets/images/potrfolio/mgc/screen6.webp';
import screen7 from '../../../assets/images/potrfolio/mgc/screen7.webp';
import screen8 from '../../../assets/images/potrfolio/mgc/screen8.webp';
import screen9 from '../../../assets/images/potrfolio/mgc/screen9.webp';
import screen10 from '../../../assets/images/potrfolio/mgc/screen10.webp';
import screen11 from '../../../assets/images/potrfolio/mgc/screen11.webp';
import screen12 from '../../../assets/images/potrfolio/mgc/screen12.webp';
import screen13 from '../../../assets/images/potrfolio/mgc/screen13.webp';
import screen14 from '../../../assets/images/potrfolio/mgc/screen14.webp';
import circleLogo from '../../../assets/images/potrfolio/mgc/circleLogo.webp';
import './MGCPage.css';

const MGCPage = () => {

    return (
        <>
            <div className='screen block__screen-mgc'>
                <img className='block__screen-mgc__slice' alt='' src={screen1}></img>
                <div className='block__screen-mgc__block-of-slice'>
                    <img className='block__screen-mgc__slice block__screen-mgc__slice_border' alt='' src={screen2}></img>
                    <div className='mgc__bg mgc__bg_blue'></div>
                </div>
                <img className='block__screen-mgc__slice' alt='' src={screen3}></img>
            </div>
            <div className='screen block__screen-mgc'>
                <img className='block__screen-mgc__slice' alt='' src={screen4}></img>
                <div className='block__screen-mgc__block-of-slice'>
                    <img className='block__screen-mgc__slice block__screen-mgc__slice_border' alt='' src={screen5}></img>
                    <div className='mgc__bg mgc__bg_green'></div>
                </div>
            </div>
            <img className='screen' alt='' src={screen6}></img>
            <div className='screen block__screen-mgc'>
                <img className='block__screen-mgc__slice' alt='' src={screen7}></img>
                <div className='block__screen-mgc__block-of-slice'>
                    <img className='block__screen-mgc__slice block__screen-mgc__slice_border' alt='' src={screen8}></img>
                    <div className='mgc__bg mgc__bg_blue'></div>
                </div>
                <img className='block__screen-mgc__slice' alt='' src={screen9}></img>
            </div>

            <div className='screen block__screen-mgc'>
                <img className='block__screen-mgc__slice' alt='' src={screen10}></img>
                <div className='block__screen-mgc__block-of-slice'>
                    <img className='block__screen-mgc__slice block__screen-mgc__slice_border' alt='' src={screen11}></img>
                    <div className='mgc__bg mgc__bg_dark-green'></div>
                </div>
                <img className='block__screen-mgc__slice' alt='' src={screen12}></img>
            </div>

            <div className='screen block__screen-mgc'>
                <img className='block__screen-mgc__slice' alt='' src={screen13}></img>
                <div className='block__screen-mgc__block-of-slice'>
                    <img className='block__screen-mgc__slice block__screen-mgc__slice_border' alt='' src={screen14}></img>
                    <div className='mgc__bg mgc__bg_blue mgc__bg_blue_last'></div>
                </div>
            </div>
            <img src={circleLogo} alt='' className='mgc__logo_circle'></img>
        </>
    )
}
export default MGCPage