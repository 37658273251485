import { useRef, useEffect } from 'react';
import './ServicesCard.css';
import bg from '../../../assets/images/services/cardbg.png';
import { RoundCloseIcon } from '../../../assets/icons/icons';

export default function ServicesCard({
  card,
  isOpen,
  isDisabled,
  onOpen,
  onClose,
}) {
  const overlay = useRef();

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) onClose(evt);
    }

    document.addEventListener('mousedown', handleOverlayClose);
    return () => document.removeEventListener('mousedown', handleOverlayClose);
  });

  return (
    <li
      className={`card ${isOpen ? 'card_opened' : ''} ${
        isDisabled ? 'card_disabled' : ''
      }`}
    >
      <button
        className={`card__content card__content_color_${card.cardBackground}`}
        type="button"
        onClick={() => onOpen(card.id)}
      >
        <button className="card__close-btn" type="button" onClick={onClose}>
          <RoundCloseIcon
            mainClassName="card__close-icon"
            fillClassName="card__close-icon-fill"
          />
        </button>
        <div className="card__text-block">
          <p
            className="card__heading"
            dangerouslySetInnerHTML={{ __html: card.heading }}
          />
          <p
            className="card__text"
            dangerouslySetInnerHTML={{ __html: card.text }}
          />
          <p className="card__numbers">
            <span className="card__numbers_size_big">{card.projects}</span>{' '}
            projects
          </p>
        </div>
        <img className="card__img" alt="" src={card.img} />
        <img className="card__bg" alt="" src={bg} />
      </button>

      <div className="card__overlay" ref={overlay} />
    </li>
  );
}
