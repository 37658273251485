import './Stats.css';

import bg from '../../../assets/images/main/stats/statsbg.webp'
import { PartLogoIcon } from '../../../assets/icons/icons';
import { STATS } from '../../../assets/utils/constants';

function Stats() {
    return (
        <div className='stats'>
            <img className='stats__bg stats__bg_pc' src={bg} alt=""></img>
            <PartLogoIcon mainClassName={'stats__logo'} />
            <div className='stats__cards'>
                {STATS.map((item, i) => (
                    <div className='stats__card' key={`stats__card-${i}`}>
                        {i === 1 ?
                            <div className='stats__card-number-box'>
                                <div className='stats__card-number-square'></div>
                                <p className='stats__card-number'>{item.count}</p>
                            </div>
                            :
                            <p className='stats__card-number'>{item.count}</p>
                        }

                        <p className='stats__card-title'>{item.title}</p>
                    </div>
                ))}

            </div>
        </div>
    );
}

export default Stats