import { useRef, useState } from 'react';
import { CloseIcon, ContactFormTriangleIcon, DropArrowIcon } from '../../../assets/icons/icons';
import Input from '../../Input/Input';
import { motion } from "framer-motion";
import './ContactUs.css';
import { CATEGORIES } from '../../../assets/utils/constants';
import Button from '../../Button/Button';
import useWindowSize from '../../../assets/hooks/useWindowSize';
import mainApi from '../../../assets/api/MainApi';
import { sendMetriс } from '../../../assets/utils/utils';
import { useLocation } from 'react-router-dom';

function ContactUs() {
    const dropContentRef = useRef(null);
    const sucsessContentRef = useRef(null)
    const windowSize = useWindowSize()
    const location = useLocation();
    const { pathname } = location;

    const [form, setForm] = useState({
        name: '',
        phone: '',
        category: '',
        isCategoryOpen: false,
        text: '',
    })

    const [formValid, setFormValid] = useState({
        phone: {},
    })

    function handleChange(e) {
        const input = e.target;
        const name = input.name;
        let value = input.value;

        switch (name) {
            case 'phone':
                let inputValue = e.target.value.replace(/\D/g, '')
                let formattedInputValue = '';
                if (!inputValue) {
                    setForm(prevValue => ({
                        ...prevValue,
                        phone: '',
                    }))
                    setFormValid(prevValue => ({
                        ...prevValue,
                        phone: {
                            errorMassage: 'Можно вводить только цифры',
                            validState: false
                        },
                    }))
                }
                else {
                    if (['7', '8', '9'].indexOf(inputValue[0]) > -1) {
                        setFormValid(prevValue => ({
                            ...prevValue,
                            phone: {
                                errorMassage: '',
                                validState: false
                            },
                        }))
                        if (inputValue[0] === '9') inputValue = '7' + inputValue;

                        let firstSimbols = (inputValue[0] === '8') ? '8' : '+7';
                        formattedInputValue = firstSimbols + ' '

                        if (inputValue.length > 1) {
                            formattedInputValue += '(' + inputValue.substring(1, 4)
                        }
                        if (inputValue.length >= 5) {
                            formattedInputValue += ') ' + inputValue.substring(4, 7)
                        }
                        if (inputValue.length >= 8) {
                            formattedInputValue += '-' + inputValue.substring(7, 9)
                        }
                        if (inputValue.length >= 10) {
                            formattedInputValue += '-' + inputValue.substring(9, 11)
                        }
                        if (inputValue.length >= 11) {
                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: true
                                },
                            }))
                        } else {

                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: false
                                },
                            }))
                        }
                    } else {
                        formattedInputValue = '+' + inputValue.substring(0, 16)
                        if (inputValue.length >= 11) {
                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: true
                                },
                            }))
                        } else {
                            setFormValid(prevValue => ({
                                ...prevValue,
                                phone: {
                                    errorMassage: '',
                                    validState: false
                                },
                            }))
                        }
                    }


                    setForm(prevValue => ({
                        ...prevValue,
                        phone: formattedInputValue,
                    }))
                }
                break;

            default:
                setForm(prevValue => ({
                    ...prevValue,
                    [name]: value
                }))
                break;
        }

    }


    function handleSelectCategory(category) {
        setForm(prevValue => ({
            ...prevValue,
            category: prevValue.category === category ? '' : category,
            isCategoryOpen: false,
        }))
    }

    function resetForm() {
        setForm({
            name: '',
            phone: '',
            category: '',
            isCategoryOpen: false,
            text: '',
        })
        setFormValid({
            phone: {},
        })
    }

    const [isPreloaderVisible, setPreloaderVisible] = useState(false)
    const [isSendSuccses, setSendSuccsess] = useState(false)

    function handleSend() {
        if (isPreloaderVisible) return
        setPreloaderVisible(true)
        mainApi.sendContactForm({
            name: form.name,
            phone: form.phone.replace(/\D/g, ''),
            category: form.category,
            text: form.text ? form.text : null,
        })
            .then(() => {
                sendMetriс('reachGoal', 'SEND_CONTACT_FORM')

            })
            .catch(() => {

            })
            .finally(() => {
                setSendSuccsess(true)
                // setTimeout(() => {
                //     setSendSuccsess(false)
                // }, 5000);
                resetForm()
                setPreloaderVisible(false)
            })

    }

    return (
        <div className='contact-us'>
            <div className='contact-us__container'>
                <div className='contact-us__container-box'>
                    <div className='contact-us__container-form'>
                        <div className='contact-us__texts'>
                            <p className='contact-us__title'><span className='contact-us__title-span'>write</span> <br className='contact-us__title-br' />to us </p>
                            <div className='contact-us__dots'>
                                {Array.from({ length: 3 }).map((item, i) => (
                                    <div className={`contact-us__dot`}></div>
                                ))}
                            </div>
                        </div>
                        <div className='contact-us__inputs-container'>
                            <div className='contact-us__inputs'>
                                <Input
                                    label={'Name*'}
                                    value={form.name}
                                    handleChange={handleChange}
                                    name={'name'}
                                />
                                <Input
                                    label={'Phone*'}
                                    value={form.phone}
                                    handleChange={handleChange}
                                    name={'phone'}
                                    error={formValid.phone}
                                    inputMode='numeric'
                                />
                                <div className='contact-us__drop-container'>
                                    <div className='contact-us__drop-value' onClick={() => {
                                        setForm(prevValue => ({
                                            ...prevValue,
                                            isCategoryOpen: !prevValue.isCategoryOpen
                                        }))
                                    }}>
                                        <p className={`contact-us__drop-label ${form.category ? 'contact-us__drop-label_selected' : ''}`}>Category*</p>
                                        <p className={`contact-us__drop-selected  ${form.category ? 'contact-us__drop-selected_active' : ''}`} dangerouslySetInnerHTML={{
                                            __html: form.category ? form.category : '&#8291;',
                                        }}></p>
                                        <DropArrowIcon
                                            mainClassName={`contact-us__drop-arrow ${form.isCategoryOpen ? 'contact-us__drop-arrow_active' : ''}`}
                                            strokeClassName={'contact-us__drop-arrow-stroke'}
                                        />
                                    </div>
                                    <motion.div
                                        className="contact-us__drop-list"
                                        ref={dropContentRef}
                                        initial={{
                                            height: "0px",
                                            // marginBottom: "0px",
                                            opacity: 0,
                                            visibility: "hidden",
                                        }}
                                        animate={{
                                            height: form.isCategoryOpen ? dropContentRef.current.scrollHeight + "px" : "0px",
                                            // marginBottom: isOpen ? "34px" : "0px",
                                            opacity: form.isCategoryOpen ? 1 : 0,
                                            visibility: form.isCategoryOpen ? "visible" : "hidden",
                                        }}
                                        transition={{ duration: 0.3, type: "tween" }}
                                    >
                                        {CATEGORIES.map((item, i) => (
                                            <button className={`contact-us__drop-list-item ${form.category === item.name ? 'contact-us__drop-list-item_selected' : ''}`} type='button' onClick={() => {
                                                handleSelectCategory(item.name)
                                            }}>{item.name}</button>
                                        ))}
                                    </motion.div>
                                </div>
                            </div>
                            <div className='contact-us__inputs'>
                                <Input
                                    label={'Message'}
                                    value={form.text}
                                    handleChange={handleChange}
                                    name={'text'}
                                    isTextArea={true}
                                    maxRows={windowSize.width > 850 ? 9 : 5}
                                    minRows={windowSize.width > 850 ? 9 : 3}
                                />
                            </div>
                        </div>
                    </div>

                    <Button
                        text={'SEND'}
                        className={'contact-us__send'}
                        onClick={handleSend}
                        isBgContrast={true}
                        isInactive={!(form.category && form.name && form.phone && formValid.phone.validState)}
                        isPreloaderVisible={isPreloaderVisible}
                    />
                    <motion.div
                        className='contact-us__sucsess'
                        ref={sucsessContentRef}
                        initial={{
                            height: "0px",
                            // marginBottom: "0px",
                            opacity: 0,
                            visibility: "hidden",
                        }}
                        animate={{
                            height: isSendSuccses ? "100%" : "0px",
                            // marginBottom: isOpen ? "34px" : "0px",
                            opacity: isSendSuccses ? 1 : 0,
                            visibility: isSendSuccses ? "visible" : "hidden",
                        }}
                        transition={{ duration: 0.3, type: "tween" }}
                    >
                        <div className='contact-us__sucsess-box'>
                            <button onClick={() => {
                                setSendSuccsess(false)
                            }} className='contact-us__sucsess-close' type='button'>
                                <CloseIcon mainClassName={'contact-us__sucsess-close-icon'} strokeClassName={'contact-us__sucsess-close-icon-stroke'} />
                            </button>
                            <p className='contact-us__sucsess-text'>Your message has been submitted. We&nbsp;appreciate your feedback and will get back to&nbsp;you as&nbsp;soon as&nbsp;possible. Thank you for reaching out!</p>
                        </div>

                    </motion.div>
                </div>

            </div>
            <ContactFormTriangleIcon
                mainClassName={'contact-us__triangle'}
                fillClassName={`contact-us__triangle-fill ${pathname === '/services' ? 'contact-us__triangle-fill_purple' : ''}`}
            />
        </div>
    );
}

export default ContactUs