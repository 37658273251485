import { useNavigate } from "react-router-dom";
import './ModalCloser.css';
const ModalCloser = () => {
    let navigate = useNavigate();


    const handleClose = () => {
        navigate(-1);
    };

    return (
            <div onClick={handleClose} className="close-icon">
                <div className="close-icon__line close-icon__line_first"></div>
                <div className="close-icon__line close-icon__line_second"></div>
            </div>
    );
};

export default ModalCloser;
