import CompTemplate from '../CompTemplate/CompTemplate';

import logo from '../../../../assets/images/main/projects/sputnik.webp'

import './Sputnik.css';

function Sputnik({company}) {
    return (
        <CompTemplate
            company={company}
            className={'sputnik-project-card'}
            project_name={'SPUTNIK'}
            project_description={'Investment company website'}
        >
            <div className='sputnik-project-card__content'>
                <div className='sputnik-project-card__bg'>
                    <div className='sputnik-project-card__bg-circle-1'></div>
                    <div className='sputnik-project-card__bg-circle-2'></div>
                </div>
                <img className='sputnik-project-card__logo' src={logo} alt="" />
            </div>
        </CompTemplate>
    );
}

export default Sputnik