import card from '../../../../assets/images/potrfolio/divanchik/divanchik_card_bg.webp'
import './Divanchik.css';
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import CompTemplate from '../CompTemplate/CompTemplate';

function Divanchik({ company }) {
    const windowSize = useWindowSize()
    return (
        <CompTemplate
            company={company}
            className={'divanchik-project-card'}
            project_name={'Divanchik'}
            project_description={'Furniture market '}
        >
            <div className='divanchik-project-card__content'>
                <img className='divanchik-project-card__content__img' src={card} alt=''></img>
            </div>
        </CompTemplate>
    );
}

export default Divanchik;