import screen1 from '../../../assets/images/potrfolio/BrainSpace/1.webp';
import screen2 from '../../../assets/images/potrfolio/BrainSpace/2.webp';
import screen3 from '../../../assets/images/potrfolio/BrainSpace/3.webp';
import emoji from '../../../assets/images/potrfolio/BrainSpace/emoji.webp';

import './BrainSpacePage.css';

const BrainSpacePage = () => {
    return (
        <>
            <img className='screen' alt='' src={screen1}></img>
            <img className='screen' alt='' src={screen2}></img>
            <img className='screen' alt='' src={screen3}></img>
            <img className='screen emoji' alt='' src={emoji}></img>
        </>
    )
}
export default BrainSpacePage