import { Link } from 'react-router-dom';
import './wlCard.css';
import { ArrowIcon } from '../../../assets/icons/whiteLabel';

export default function WhiteLabelCard({ card, isSwiper }) {
  return (
    <li className={`wl-card ${card.cardBackground}`}>
      <Link
        className={`wl-card__link ${isSwiper ? 'wl-card__link_size_short' : ''}`}
        to={`/white-label-solutions/${card.translitName}`}
      >
        <p
          className="wl-card__title"
          dangerouslySetInnerHTML={{ __html: card.heading }}
        />
        {!isSwiper ? (
          <ArrowIcon
            mainClassName="wl-card__arrow"
            fillClassName="wl-card__arrow-fill"
          />
        ) : null}
        <img className="wl-card__icon" src={card.icon} alt="" />
      </Link>
    </li>
  );
}
