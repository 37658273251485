
import logo from '../../../assets/images/potrfolio/jelly/logo.webp';
import screen1 from '../../../assets/images/potrfolio/jelly/screen1.webp';
import screen2 from '../../../assets/images/potrfolio/jelly/screen2.webp';
import screen3 from '../../../assets/images/potrfolio/jelly/screen3.webp';
import screen4 from '../../../assets/images/potrfolio/jelly/screen4.webp';
import screen5 from '../../../assets/images/potrfolio/jelly/screen5.webp';
import screen6 from '../../../assets/images/potrfolio/jelly/screen6.webp';
import popup1 from '../../../assets/images/potrfolio/jelly/popup1.webp';
import popup2 from '../../../assets/images/potrfolio/jelly/popup2.webp';
import './JellyBetPage.css';

const JellyBetPage = () => {

    return (
        <>
            <img className='jelly__logo' alt='' src={logo}></img>

            <img className='screen' alt='' src={screen1}></img>
            <img className='screen' alt='' src={screen2}></img>
            <img className='screen' alt='' src={screen3}></img>
            <img className='screen' alt='' src={screen4}></img>
            <img className='screen' alt='' src={screen5}></img>
            <img className='screen' alt='' src={screen6}></img>
            <div className='jelly__popups-box'>
            <img className='jelly__popup' alt='' src={popup1}></img>
            <img className='jelly__popup' alt='' src={popup2}></img>
            </div>
        </>
    )
}
export default JellyBetPage