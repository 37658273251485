import './CabaSuite.css';

import card from '../../../../assets/images/potrfolio/cabasuite/card.png'
import useWindowSize from '../../../../assets/hooks/useWindowSize';
import { Link, useLocation } from 'react-router-dom';
import CardTemplate from '../CardTemplate/CardTemplate';

function CabaSuite({ company }) {
    const windowSize = useWindowSize()
    let location = useLocation();
    return (
        <CardTemplate
            className={'cabasuite-project-card'}
            project_name={'Caba Suite'}
            company={company}
            project_description={windowSize.width > 760 ? 'Unique CRM platform' : ''}
        >
            <Link to={`/${company}`} state={{ backgroundLocation: location }}>
                <div className='cabasuite-project-card__content'>
                    <img className='cabasuite-project-card__content__img' src={card} alt=''></img>
                </div>
            </Link>
        </CardTemplate>

    );
}

export default CabaSuite